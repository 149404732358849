import { displayBigNumber } from "@/utils/displayBigNumber";
import { isNumber, parseNumber } from "@/utils/parseNumber";

export function exchangeCurrency({ amount, fromCurrency, toCurrency, rates, }: {
	amount: number;
	fromCurrency: string;
	toCurrency: string;
	rates: ({
		currencyISO: string;
		rate: number;
		currencyName: string;
	})[]
}) {
    amount = isNumber(amount) ? parseNumber(amount, { notNull: true, }) : null;
    
	const fromRate = rates.filter(r => r.currencyISO === fromCurrency)[0];
	const toRate = rates.filter(r => r.currencyISO === toCurrency)[0];

	let exchangedAmount = amount;

	if (!(amount && toRate && fromRate) || isNaN(amount) || (fromCurrency === toCurrency)) {
		// do nothing
	} else {
		exchangedAmount = (toRate.rate / fromRate.rate) * amount;
		if (exchangedAmount) exchangedAmount = Number(exchangedAmount.toFixed(2));
	}
	return {
		amount: exchangedAmount,
		formatedAmount: displayBigNumber(exchangedAmount),
		currencyISO: toRate?.currencyISO || fromCurrency,
		currencyName: toRate?.currencyName || null,
	};
}
