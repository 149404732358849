import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Page from '../views/Page.vue';
import { AuthService } from '@/services/auth.service';
import { computed, watch } from 'vue';
import store from '@/store';
import { BaseService } from '@/services/base.service';
//For region
import * as regionConstants from '@/components/list/regions/constants';
//For office
import * as officeConstants from '@/components/list/offices/constants';
import { MetaDataRoutes } from './routeMetadataRequirements';

const routes: any = [
  {
    path: '/',
    name: 'Index',
    component: Page,
    redirect: {
      name: 'Dashboard',
    },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "dashboard" */ '../modules/dashboard-page/views/IndexDashboardView.vue'),
        props: {
          breadcrumb_name: 'Dashboard',
          breadcrumbs_ancestry: [],
        },
        redirect: '/dashboard/summary',
        children: [
          {
            path: '/dashboard/widgets',
            name: 'Widget Dashboard',
            component: () =>
              import(/* webpackChunkName: "create" */ '../modules/dashboard-page/views/widgets/WidgetDashboard.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/dashboard/summary',
            name: 'Summary',
            component: () =>
              import(/* webpackChunkName: "create" */ '../modules/dashboard-page/views/summary/SummaryDashboard.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/dashboard/recruitment-and-retention',
            name: 'Recruitment',
            component: () =>
              import(/* webpackChunkName: "create" */ '../modules/dashboard-page/views/recruitment/Recruitment.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/dashboard/office-overview',
            name: 'Office Overview',
            component: () =>
              import(
                /* webpackChunkName: "create" */ '../modules/dashboard-page/views/office-overview/OfficeOverviewDashboard.vue'
              ),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/dashboard/office-dashboard/:office_id',
            name: 'Office Dashboard',
            component: () =>
              import(/* webpackChunkName: "create" */ '../modules/dashboard-page/views/office-dashboard/index.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/dashboard/glossary',
            name: 'Data Glossary',
            component: () => import(/* webpackChunkName: "create" */ '../modules/dashboard-page/views/glossary/index.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/dashboard/landingpage',
            name: 'Landing Page',
            component: () => import(/* webpackChunkName: "create" */ '../modules/dashboard-page/views/landingpage/index.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/dashboard/franchise-sales',
            name: 'Franchise Sales',
            component: () => import('../modules/dashboard-page/views/franchise-sales/index.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/dashboard/affiliate-dashboard',
            name: 'Affiliate Dashboard',
            component: () => import('../modules/dashboard-page/views/affiliate-dashboard/index.vue'),
            beforeEnter: [checkPermissions],
          },
        ],
      },
      {
        path: '/regions',
        name: 'Regions',
        component: () => import(/* webpackChunkName: "regions" */ '../modules/regions-page/views/IndexRegionsView.vue'),
        beforeEnter: [checkPermissions],
        props: {
          breadcrumb_name: 'Regions',
          breadcrumbs_ancestry: [],
        },
        children: [
          {
            path: '/regions/create',
            name: 'Create Region',
            component: () => import(/* webpackChunkName: "regions" */ '../modules/regions-page/views/CreateRegionsView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Create',
              breadcrumbs_ancestry: ['/regions'],
            },
          },
          {
            path: '/regions/:region_id/view',
            name: 'Read Region',
            component: () => import(/* webpackChunkName: "regions" */ '../modules/regions-page/views/ReadRegionsView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'View',
              breadcrumbs_ancestry: ['/regions'],
            },
          },
          // {
          //   path: '/regions/:region_id',
          //   name: 'ViewRegion',
          //   component: () => import(/* webpackChunkName: "regions" */ '../modules/regions-page/views/ReadRegionsView.vue'),
          //   props: {
          //     breadcrumb_name: 'View',
          //     breadcrumbs_ancestry: ['/regions'],
          //   },
          // },
          {
            path: '/regions/:region_id/edit',
            name: 'Edit Region',
            component: () => import(/* webpackChunkName: "regions" */ '../modules/regions-page/views/EditRegionsView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Edit',
              breadcrumbs_ancestry: ['/regions', '/regions/:region_id'],
            },
          },
        ],
      },
      // /offices
      {
        path: '/offices',
        name: 'Offices',
        component: () => import(/* webpackChunkName: "offices" */ '../modules/offices-page/views/IndexOfficesView.vue'),
        redirect: {
          name: 'Office Table',
        },
        children: [
          {
            path: '/offices/table',
            name: 'Office Table',
            component: () => import(/* webpackChunkName: "table" */ '../modules/offices-page/views/ListOfficesView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Offices',
              breadcrumbs_ancestry: [],
            },
          },
          {
            path: '/offices/create-office',
            name: 'Create Office',
            component: () => import(/* webpackChunkName: "create" */ '../modules/offices-page/views/CreateOfficesView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Create',
              breadcrumbs_ancestry: ['/office/table'],
            },
          },
          {
            path: '/offices/:office_id/edit',
            name: 'EditOffice',
            component: () => import(/* webpackChunkName: "create" */ '../modules/offices-page/views/EditOfficesView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Edit',
              breadcrumbs_ancestry: ['/office/table'],
            },
          },
          {
            path: '/offices/:office_id/view',
            name: 'ViewOffice',
            component: () => import(/* webpackChunkName: "create" */ '../modules/offices-page/views/ReadOffice.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'View',
              breadcrumbs_ancestry: ['/office/table'],
            },
          },
        ],
      },
      {
        path: '/companies',
        name: 'companies',
        component: () => import(/* webpackChunkName: "offices" */ '../modules/companies-page-2/views/IndexOfficesView.vue'),
        redirect: {
          name: 'Company Table',
        },
        children: [
          {
            path: '/companies/table',
            name: 'Company Table',
            component: () => import(/* webpackChunkName: "table" */ '../modules/companies-page-2/views/ListOfficesView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Companies',
              breadcrumbs_ancestry: [],
            },
          },
          {
            path: '/companies/create-company',
            name: 'Create Company',
            component: () => import(/* webpackChunkName: "create" */ '../modules/companies-page-2/views/CreateOfficesView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Create',
              breadcrumbs_ancestry: ['/company/table'],
            },
          },
          {
            path: '/companies/:company_id/edit',
            name: 'EditCompany',
            component: () => import(/* webpackChunkName: "create" */ '../modules/companies-page-2/views/EditOfficesView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Edit',
              breadcrumbs_ancestry: ['/company/table'],
            },
          },
          {
            path: '/companies/:company_id/view',
            name: 'ReadCompany',
            component: () => import(/* webpackChunkName: "create" */ '../modules/companies-page-2/views/ReadCompany.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Office',
              breadcrumbs_ancestry: ['/offices/table'],
            },
          },
        ],
      },
      // /contracts
      {
        path: '/contracts',
        name: 'Contracts',
        redirect: '/contracts/saved-for-later',
        children: [
          {
            path: '/contracts/sale/create',
            name: 'CreateSaleContractIndex',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/CreateSaleContract.vue'),
            beforeEnter: [checkPermissions],
            children: [
              {
                path: '/contracts/sale/create/:section',
                name: 'CreateSaleContract',
                component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/CreateSaleContract.vue'),
                beforeEnter: [checkPermissions],
              },
            ],
          },
          {
            path: '/contracts/sale/edit-draft/:contract_id',
            name: 'EditSaleContractDraftIndex',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/EditSaleContractDraft.vue'),
            beforeEnter: [checkPermissions],
            children: [
              {
                path: '/contracts/sale/edit-draft/:contract_id/:section',
                name: 'EditSaleContractDraft',
                component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/EditSaleContractDraft.vue'),
                beforeEnter: [checkPermissions],
              },
            ],
          },
          {
            path: '/contracts/sale/edit/:contract_id',
            name: 'EditSaleContractIndex',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/EditSaleContract.vue'),
            beforeEnter: [checkPermissions],
            children: [
              {
                path: '/contracts/sale/edit/:contract_id/:section',
                name: 'EditSaleContract',
                component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/EditSaleContract.vue'),
                beforeEnter: [checkPermissions],
              },
            ],
          },
          {
            path: '/contracts/sale/review/:contract_id/:contract_log_id',
            name: 'ReviewSaleContract',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/ReviewSaleContract.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/contracts/renewal/create',
            name: 'CreateRenewalContractIndex',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/CreateRenewalContract.vue'),
            beforeEnter: [checkPermissions],
            children: [
              {
                path: '/contracts/renewal/create/:section',
                name: 'CreateRenewalContract',
                component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/CreateRenewalContract.vue'),
                beforeEnter: [checkPermissions],
              },
            ],
          },
          {
            path: '/contracts/renewal/edit-draft/:contract_id',
            name: 'EditRenewalContractDraftIndex',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/EditRenewalContractDraft.vue'),
            beforeEnter: [checkPermissions],
            children: [
              {
                path: '/contracts/renewal/edit-draft/:contract_id/:section',
                name: 'EditRenewalContractDraft',
                component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/EditRenewalContractDraft.vue'),
                beforeEnter: [checkPermissions],
              },
            ],
          },
          {
            path: '/contracts/renewal/edit/:contract_id',
            name: 'EditRenewalContractIndex',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/EditRenewalContract.vue'),
            beforeEnter: [checkPermissions],
            children: [
              {
                path: '/contracts/renewal/edit/:contract_id/:section',
                name: 'EditRenewalContract',
                component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/EditRenewalContract.vue'),
                beforeEnter: [checkPermissions],
              },
            ],
          },
          {
            path: '/contracts/renewal/review/:contract_id/:contract_log_id',
            name: 'ReviewRenewalContract',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/ReviewRenewalContract.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/contracts/transfer/create',
            name: 'CreateTransferContractIndex',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/CreateTransferContract.vue'),
            beforeEnter: [checkPermissions],
            children: [
              {
                path: '/contracts/transfer/create/:section',
                name: 'CreateTransferContract',
                component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/CreateTransferContract.vue'),
                beforeEnter: [checkPermissions],
              },
            ],
          },
          {
            path: '/contracts/transfer/edit-draft/:contract_id',
            name: 'EditTransferContractDraftIndex',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/EditTransferContractDraft.vue'),
            beforeEnter: [checkPermissions],
            children: [
              {
                path: '/contracts/transfer/edit-draft/:contract_id/:section',
                name: 'EditTransferContractDraft',
                component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/EditTransferContractDraft.vue'),
                beforeEnter: [checkPermissions],
              },
            ],
          },
          {
            path: '/contracts/transfer/edit/:contract_id',
            name: 'EditTransferContractIndex',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/EditTransferContract.vue'),
            beforeEnter: [checkPermissions],
            children: [
              {
                path: '/contracts/transfer/edit/:contract_id/:section',
                name: 'EditTransferContract',
                component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/EditTransferContract.vue'),
                beforeEnter: [checkPermissions],
              },
            ],
          },
          {
            path: '/contracts/transfer/review/:contract_id/:contract_log_id',
            name: 'ReviewTransferContract',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/editor/ReviewTransferContract.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/contracts/saved-for-later',
            name: 'SavedForLater',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/SavedForLater.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/contracts/:section',
            name: 'ContractsLog',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/ContractsLog.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/contracts/fee-approval-queue',
            name: 'FeeApprovalQueue',
            component: () => import(/* webpackChunkName: "create" */ '../modules/cm/FeeApprovalQueue.vue'),
            beforeEnter: [checkPermissions],
          },
        ],
      },
      // /persons
      {
        path: '/persons',
        name: 'Persons',
        redirect: {
          name: 'Persons Table',
        },
        component: () => import(/* webpackChunkName: "persons" */ '../modules/persons-page/views/IndexPersonsView.vue'),
        children: [
          {
            path: '/persons/table',
            name: 'Persons Table',
            component: () => import(/* webpackChunkName: "table" */ '../modules/persons-page/views/ListPersonsView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Persons',
              breadcrumbs_ancestry: [],
            },
          },
          {
            path: '/persons/create-person',
            name: 'CreatePerson',
            component: () => import(/* webpackChunkName: "create" */ '../modules/persons-page/views/CreatePersonsView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Create',
              breadcrumbs_ancestry: ['/persons/table'],
            },
          },
          // {
          //   path: '/persons/create-Persons',
          //   name: 'Create Persons',
          //   component: () => import(/* webpackChunkName: "create" */ '../modules/persons-page/views/CreatePersonsView.vue'),
          //   props: {
          //     breadcrumb_name: 'Create',
          //     breadcrumbs_ancestry: ['/persons/table'],
          //   },
          // },
          {
            path: '/persons/:id',
            name: 'ViewPerson',
            component: () => import(/* webpackChunkName: "companies-id" */ '../modules/persons-page/views/ViewPerson.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'View',
              breadcrumbs_ancestry: ['/persons/table'],
            },
          },
          {
            path: '/persons/:id/edit',
            name: 'EditPerson',
            component: () => import(/* webpackChunkName: "companies-id" */ '../modules/persons-page/views/EditPersonsView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Edit',
              breadcrumbs_ancestry: ['/persons/table'],
            },
          },
          // {
          //   path: '/persons/edit/:id',
          //   name: 'Edit Person',
          //   component: () =>
          //     import(/* webpackChunkName: "companies-id" */ '../modules/persons-page/views/EditPersonsView.vue'),
          //   props: {
          //     breadcrumb_name: 'Edit',
          //     breadcrumbs_ancestry: ['/persons/table'],
          //   },
          // },
          // {
          //   path: '/persons/:first_name:last_name/:unique_id/edit',
          //   name: 'Edit Person',
          //   component: () =>
          //     import(/* webpackChunkName: "companies-id" */ '../modules/persons-page/views/EditPersonsView.vue'),
          //   props: {
          //     breadcrumb_name: 'Edit',
          //     breadcrumbs_ancestry: ['/persons/table'],
          //   },
          // },
        ],
      },
      // /monthly-reporting
      {
        path: '/monthly-reporting',
        name: 'Monthly Reporting',
        component: () =>
          import(
            /* webpackChunkName: "monthly-reporting" */ '../modules/monthly-reporting-page/views/IndexMonthlyReportingView.vue'
          ),
        props: {
          breadcrumb_name: 'Monthly Reporting',
          breadcrumbs_ancestry: [],
        },
        redirect: {
          path: '/monthly-reporting/regions',
        },
        children: [
          {
            path: '/monthly-reporting/regions',
            name: 'Monthly Reporting - Regions',
            component: () =>
              import(
                /* webpackChunkName: "monthly-reporting" */ '../modules/monthly-reporting-page/views/ListMonthlyReportingView.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Reporting',
              breadcrumbs_ancestry: [],
            },
          },
          {
            path: '/monthly-reporting/region/monthly-overview/:regionID/:region_name',
            name: 'MonthlyReportingRegionsReadMonths',
            component: () =>
              import(
                /* webpackChunkName: "monthly-reporting-region" */ '../modules/monthly-reporting-page/views/ReadMonthlyReportingView.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: ':region_name',
              breadcrumbs_ancestry: ['/monthly-reporting'],
            },
          },
          {
            path: '/monthly-reporting/regions/:regionID/:region_name/months/:month_id/:month_name',
            name: 'Monthly Reporting - Regions - Read - Months - Read',
            component: () =>
              import(
                /* webpackChunkName: "monthly-reporting-o" */ '../modules/monthly-reporting-page/modules/offices/views/ReadOfficesView.vue'
              ),
            props: {
              breadcrumb_name: ':month_name',
              breadcrumbs_ancestry: [
                '/monthly-reporting',
                '/monthly-reporting/regions',
                '/monthly-reporting/regions/:regionID/:region_name/months',
              ],
            },
            redirect: (to) => {
              return {
                path:
                  '/monthly-reporting/regions/' +
                  to.params.regionID +
                  '/' +
                  encodeURIComponent(String(to.params.region_name)) +
                  '/months/' +
                  to.params.month_id +
                  '/' +
                  encodeURIComponent(String(to.params.month_name)) +
                  '/offices',
              };
            },
          },
          {
            path: '/monthly-reporting/region/:regionID/:month/:year/:region_name',
            name: 'MonthlyReporting-Regions-Read-Months-Read-Offices',
            component: () => import('../modules/monthly-reporting-page/modules/offices/views/ListOfficesView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: ':region_name',
              breadcrumbs_ancestry: ['/monthly-reporting'],
            },
          },
          {
            path: '/monthly-reporting/region/:regionID/:month/:year/:region_name/upload',
            name: 'Monthly Reporting upload',
            component: () => import('../components/editor/MonthlyReporting/Upload/index.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Upload',
              breadcrumbs_ancestry: ['/monthly-reporting', '/Office-overview'],
            },
          },
          {
            path: '/monthly-reporting/office/:regionID/:month/:year/:office_id/:region_name/:office_name/:unique_office_id',
            name: 'MonthlyReporting-Regions-Read-Months-Read-Offices-Edit',
            component: () => import('../components/editor/MonthlyReporting/index.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Edit',
              breadcrumbs_ancestry: ['/monthly-reporting'],
            },
          },

          {
            path: '/monthly-reporting/regions/:regionID/:region_name/months/:month_id/:month_name/offices/:offices_id/:offices_name/upload',
            name: 'Monthly Reporting - Regions - Read - Months - Read - Offices - Edit - Upload',
            component: () =>
              import(
                /* webpackChunkName: "create" */ '../modules/monthly-reporting-page/modules/offices/views/UploadExcelForOfficesView.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Upload',
              breadcrumbs_ancestry: [
                '/monthly-reporting',
                '/monthly-reporting/regions',
                '/monthly-reporting/regions/:regionID/:region_name/months',
                '/monthly-reporting/regions/:regionID/:region_name/months/:month_id/:month_name',
                '/monthly-reporting/regions/:regionID/:region_name/months/:month_id/:month_name/offices',
                '/monthly-reporting/regions/:regionID/:region_name/months/:month_id/:month_name/offices/:offices_id/:offices_name',
              ],
            },
          },
        ],
      },
      // /annual-dues
      {
        path: '/annual-dues',
        name: 'annual Dues',
        component: () => import(/* webpackChunkName: "offices" */ '../modules/annual-dues-page/views/IndexAnnualDuesView.vue'),
        // up till here
        redirect: {
          name: 'AnnualDuesReportsOverview',
        },
        props: {
          breadcrumb_name: 'Annual Dues',
          breadcrumbs_ancestry: [],
        },
        children: [
          {
            path: '/annual-dues/reports',
            name: 'Annual Reports',
            component: () =>
              import(
                /* webpackChunkName: "offices" */ '../modules/annual-dues-page/modules/reports-page/views/IndexReportsView.vue'
              ),
            redirect: { name: 'AnnualDuesReportsOverview' },
            children: [
              {
                path: '/annual-dues/reports/overview',
                name: 'AnnualDuesReportsOverview',
                component: () =>
                  import(
                    /* webpackChunkName: "table" */ '../modules/annual-dues-page/modules/reports-page/views/ReportsOverview.vue'
                  ),
                beforeEnter: [checkPermissions],
              },
              {
                path: '/annual-dues/reports/:regionID/monthly-overview',
                name: 'AnnualDuesReportsMonthlyOverview',
                component: () =>
                  import(
                    /* webpackChunkName: "table" */ '../modules/annual-dues-page/modules/reports-page/views/MonthlyOverview.vue'
                  ),
                beforeEnter: [checkPermissions],
              },
              {
                path: '/annual-dues/reports/:regionID/current',
                name: 'AnnualDuesReportsCurrent',
                component: () =>
                  import(
                    /* webpackChunkName: "table" */ '../modules/annual-dues-page/modules/reports-page/views/CurrentReport.vue'
                  ),
                beforeEnter: [checkPermissions],
              },
              {
                path: '/annual-dues/reports/:regionID/financial-overview',
                name: 'AnnualDuesReportsFinancialOverview',
                component: () =>
                  import(
                    /* webpackChunkName: "table" */ '../modules/annual-dues-page/modules/reports-page/views/FinancialOverview.vue'
                  ),
                beforeEnter: [checkPermissions],
              },
              {
                path: '/annual-dues/reports/:regionID/add-finance-payment',
                name: 'AnnualDuesReportsAddFinancePayment',
                component: () =>
                  import(
                    /* webpackChunkName: "table" */ '../modules/annual-dues-page/modules/reports-page/views/AddFinancePayment.vue'
                  ),
                beforeEnter: [checkPermissions],
              },
              {
                path: '/annual-dues/reports/:regionID/finance-payment/:finance_id/edit',
                name: 'AnnualDuesReportsEditFinancePayment',
                component: () =>
                  import(
                    /* webpackChunkName: "table" */ '../modules/annual-dues-page/modules/reports-page/views/EditFinancePayment.vue'
                  ),
                beforeEnter: [checkPermissions],
              },
            ],
          },
          {
            path: '/annual-dues/fee-reliefs',
            name: 'Fee Reliefs',
            component: () =>
              import(
                /* webpackChunkName: "offices" */ '../modules/annual-dues-page/modules/fee-relief-page/views/IndexFeeReliefView.vue'
              ),
            redirect: {
              name: 'Fee Relief Table',
            },
            children: [
              {
                path: '/annual-dues/fee-reliefs/table',
                name: 'Fee Relief Table',
                component: () =>
                  import(
                    /* webpackChunkName: "table" */ '../modules/annual-dues-page/modules/fee-relief-page/views/ListFeeReliefView.vue'
                  ),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'Fee Relief',
                  breadcrumbs_ancestry: ['/annual-dues'],
                },
              },
              {
                path: '/annual-dues/fee-reliefs/create-fee-relief',
                name: 'Create Fee Relief',
                component: () =>
                  import(
                    /* webpackChunkName: "table" */ '../modules/annual-dues-page/modules/fee-relief-page/views/CreateFeeReliefView.vue'
                  ),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'Create',
                  breadcrumbs_ancestry: ['/annual-dues', '/annual-dues/fee-reliefs/table'],
                },
              },
              {
                path: '/annual-dues/fee-reliefs/edit/:id',
                name: 'EditFeeRelief',
                component: () =>
                  import(
                    /* webpackChunkName: "table" */ '../modules/annual-dues-page/modules/fee-relief-page/views/EditFeeReliefView.vue'
                  ),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'Edit',
                  breadcrumbs_ancestry: ['/annual-dues', '/annual-dues/fee-reliefs/table'],
                },
              },
              {
                path: '/annual-dues/fee-reliefs/view/:id',
                name: 'ViewFeeRelief',
                component: () =>
                  import(
                    /* webpackChunkName: "table" */ '../modules/annual-dues-page/modules/fee-relief-page/views/ViewFeeRelief.vue'
                  ),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'View',
                  breadcrumbs_ancestry: ['/annual-dues', '/annual-dues/fee-reliefs/table'],
                },
              },
              {
                path: '/annual-dues/fee-reliefs/edit',
                name: 'Edit Fee Relief',
                component: () =>
                  import(
                    /* webpackChunkName: "table" */ '../modules/annual-dues-page/modules/fee-relief-page/views/EditFeeReliefView.vue'
                  ),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'Edit',
                  breadcrumbs_ancestry: ['/annual-dues', '/annual-dues/fee-reliefs/table'],
                },
              },
            ],
          },
        ],
      },

      // /awards
      {
        path: '/awards',
        name: 'Awards',
        component: () => import(/* webpackChunkName: "awards" */ '../modules/awards-page/views/IndexAwardsView.vue'),
        beforeEnter: [checkPermissions],
        props: {
          breadcrumb_name: 'Awards',
          breadcrumbs_ancestry: [],
        },
      },
      // /teams
      {
        path: '/teams',
        name: 'Teams',
        component: () => import(/* webpackChunkName: "offices" */ '../modules/teams-page/views/IndexTeamsView.vue'),
        redirect: {
          name: 'Teams Table',
        },
        children: [
          {
            path: '/teams/table',
            name: 'Teams Table',
            component: () => import(/* webpackChunkName: "table" */ '../modules/teams-page/views/ListTeamsView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Teams',
              breadcrumbs_ancestry: [],
            },
          },
          {
            path: '/teams/create-team',
            name: 'CreateTeam',
            component: () => import(/* webpackChunkName: "create" */ '../modules/teams-page/views/CreateTeamsView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Create',
              breadcrumbs_ancestry: ['/teams/table'],
            },
          },
          {
            path: '/teams/edit/:team_id',
            name: 'EditTeam',
            component: () => import(/* webpackChunkName: "edit" */ '../modules/teams-page/views/EditTeam.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Edit',
              breadcrumbs_ancestry: ['/teams/table'],
            },
          },
          {
            path: '/teams/view/:team_id',
            name: 'ViewTeam',
            component: () => import(/* webpackChunkName: "view" */ '../modules/teams-page/views/ViewTeam.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'View',
              breadcrumbs_ancestry: ['/teams/table'],
            },
          },
        ],
      },
      // /office-groups
      {
        path: '/office-groups',
        name: 'Office Groups',
        redirect: {
          name: 'Office Groups Table',
        },
        component: () =>
          import(/* webpackChunkName: "persons" */ '../modules/office-groups-page/views/IndexMultiBrokerageView.vue'),
        props: {
          breadcrumb_name: 'Office Groups',
          breadcrumbs_ancestry: [],
        },
        children: [
          {
            path: '/office-groups/table',
            name: 'Office Groups Table',
            component: () =>
              import(/* webpackChunkName: "table" */ '../modules/office-groups-page/views/ListMultiBrokerageView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Office Groups',
              breadcrumbs_ancestry: [''],
            },
          },
          {
            path: '/office-groups/:officeGroupID/view/ungrouped-offices',
            name: 'View Ungrouped Offices',
            component: () => import(/* webpackChunkName: "table" */ '../modules/office-groups-page/views/ViewGroup.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'View Ungrouped Groups',
              breadcrumbs_ancestry: ['/office-groups'],
            },
          },
          {
            path: '/office-groups/:officeGroupID/edit/ungrouped-offices',
            name: 'Edit Ungrouped Offices',
            component: () =>
              import(/* webpackChunkName: "table" */ '../modules/office-groups-page/views/EditMultiBrokerageView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Edit Ungrouped Offices',
              breadcrumbs_ancestry: ['/office-groups'],
            },
          },
          {
            path: '/office-groups/create-office-group',
            name: 'Create Office Group',
            component: () =>
              import(/* webpackChunkName: "table" */ '../modules/office-groups-page/views/CreateMultiBrokerageView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Create',
              breadcrumbs_ancestry: ['/office-groups/', '/office-groups/table'],
            },
          },
          {
            path: '/office-groups/:officeGroupID/view',
            name: 'View Office Groups',
            component: () => import(/* webpackChunkName: "table" */ '../modules/office-groups-page/views/ViewGroup.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'View',
              breadcrumbs_ancestry: ['/office-groups'],
            },
          },
          {
            path: '/office-groups/:officeGroupID/edit',
            name: 'Edit Office Groups',
            component: () =>
              import(/* webpackChunkName: "table" */ '../modules/office-groups-page/views/EditMultiBrokerageView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Edit',
              breadcrumbs_ancestry: ['/office-groups'],
            },
          },
        ],
      },
      // /contacts
      {
        path: '/contacts',
        name: 'Contacts',
        component: () => import(/* webpackChunkName: "login" */ '../modules/contacts-page/views/IndexContactsView.vue'),
        redirect: { path: '/contacts/list' },
        children: [
          {
            path: '/contacts/list',
            name: 'Contacts List',
            component: () => import(/* webpackChunkName: "create" */ '../modules/contacts-page/views/IndexContactsView.vue'),
            beforeEnter: [checkPermissions],
          },

          {
            path: '/contacts/:contact_type/create',
            name: 'CreateContact',
            component: () => import(/* webpackChunkName: "create" */ '../modules/contacts-page/views/CreateContact.vue'),
            beforeEnter: [checkPermissions],
          },

          {
            path: '/contacts/:contact_type/:id/edit',
            name: 'EditContact',
            component: () => import(/* webpackChunkName: "create" */ '../modules/contacts-page/views/EditContact.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/contacts/:contact_type/:id/view',
            name: 'ViewContact',
            component: () => import(/* webpackChunkName: "create" */ '../modules/contacts-page/views/ViewContact.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: ':contact_name',
              breadcrumbs_ancestry: ['/contacts/list'],
            },
          },
        ],
      },
      {
        path: '/contacts/sync-log',
        name: 'SyncLog',
        component: () => import(/* webpackChunkName: "create" */ '../modules/contacts-page/views/SyncLogContactsView.vue'),
        redirect: {
          path: '/contacts/sync-log/list',
        },
        children: [
          {
            path: '/contacts/sync-log/list',
            name: 'SyncLogList',
            component: () => import(/* webpackChunkName: "create" */ '../modules/contacts-page/views/SyncLogContactsView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'List',
              breadcrumbs_ancestry: ['/contacts/syncLog/list'],
            },
          },
          {
            path: '/contacts/sync-log/view',
            name: 'ViewSyncLog',
            component: () => import(/* webpackChunkName: "create" */ '../modules/contacts-page/views/SyncLogReadView.vue'),
            beforeEnter: [checkPermissions],
            // props: {
            //   breadcrumb_name: 'View',
            //   breadcrumbs_ancestry: ['/contacts/list'],
            // },
          },
        ],
      },
      // /exchange-rates
      {
        path: '/exchange-rates',
        name: 'Exchange Rates',
        component: () =>
          import(/* webpackChunkName: "exchange rates" */ '../modules/exchange-rates-page/views/IndexExchangeRatesView.vue'),
        props: {
          breadcrumb_name: 'Exchange Rates',
          breadcrumbs_ancestry: [],
        },
        redirect: {
          path: '/exchange-rates/euro',
        },
        children: [
          {
            path: '/exchange-rates/euro',
            name: 'Exchange Rates - Euro',
            component: () =>
              import(
                /* webpackChunkName: "exchange-rates-euro" */ '../modules/exchange-rates-page/modules/euro/views/IndexEuroView.vue'
              ),
            props: {
              breadcrumb_name: 'Euro',
              breadcrumbs_ancestry: ['/exchange-rates'],
            },
            redirect: {
              path: '/exchange-rates/euro/months',
            },
          },
          {
            path: '/exchange-rates/euro/months',
            name: 'Exchange Rates - Euro - Months',
            component: () =>
              import(
                /* webpackChunkName: "exchange-rates-euro" */ '../modules/exchange-rates-page/modules/euro/views/ListEuroView.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Months',
              breadcrumbs_ancestry: ['/exchange-rates', '/exchange-rates/euro'],
            },
          },
          // {
          //   path: '/exchange-rates/euro/months/:months_id',
          //   name: 'Exchange Rates - Euro - Months - Read',
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "exchange-rates-euro" */ '../modules/exchange-rates-page/modules/euro/views/ReadEuroView.vue'
          //     ),
          //   props: {
          //     breadcrumb_name: ':months_id',
          //     breadcrumbs_ancestry: ['/exchange-rates', '/exchange-rates/euro', '/exchange-rates/euro/months'],
          //   },
          //   // redirect: {
          //   //   path: '/exchange-rates/euro/months/:months_id/exchange-rates',
          //   // },
          //   redirect: (to) => {
          //     return { path: '/exchange-rates/euro/months/' + to.params.months + '/exchange-rates' };
          //    // return { path: '/exchange-rates/euro/months/4/exchange-rates' };
          //   },
          // },

          {
            path: '/exchange-rates/euro/year/:year/month/:month/exchange-rates',
            name: 'Exchange Rates - Euro - Months - Read - Exchange Rates',
            component: () =>
              import(
                /* webpackChunkName: "exchange-rates-euro" */ '../modules/exchange-rates-page/modules/euro/views/ReadEuroView.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Exchange Rates',
              breadcrumbs_ancestry: [
                '/exchange-rates',
                '/exchange-rates/euro',
                '/exchange-rates/euro/months',
                '/exchange-rates/euro/months/:months_id',
              ],
            },
          },
          {
            path: '/exchange-rates/euro/year/:year/month/:month/currency/:isocurrency',
            name: 'Days',
            component: () =>
              import(
                /* webpackChunkName: "exchange-rates-euro" */ '../modules/exchange-rates-page/modules/euro/modules/exchange-rates/views/ReadExchangeRatesView.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Exchange Rates - Daily',
              breadcrumbs_ancestry: [
                '/exchange-rates',
                '/exchange-rates/euro',
                '/exchange-rates/euro/months',
                //'/exchange-rates/euro/months/:months_id',
                '/exchange-rates/euro/months/:months_id/exchange-rates',
                '/exchange-rates/euro/year/:year/month/:month/exchange-rates',
              ],
            },
            // redirect: (to) => {
            //   return {
            //     path: '/exchange-rates/euro/months/' + to.params.months_id + '/exchange-rates/' + to.params.rate_id + '/day',
            //   };
            // },
          },
          {
            path: '/exchange-rates/euro/months/:months_id/exchange-rates/:rate_id/day',
            name: 'Exchange Rates - Euro - Months - Read - Exchange Rates - Read - Days',
            component: () =>
              import(
                /* webpackChunkName: "exchange-rates-euro" */ '../modules/exchange-rates-page/modules/euro/modules/exchange-rates/views/ReadExchangeRatesView.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Days',
              breadcrumbs_ancestry: [
                '/exchange-rates',
                '/exchange-rates/euro',
                '/exchange-rates/euro/months',
                '/exchange-rates/euro/months/:months_id',
                '/exchange-rates/euro/months/:months_id/exchange-rates',
                '/exchange-rates/euro/months/:months_id/exchange-rates/:rate_id',
              ],
            },
          },
          //Euro end, International Dollar start
          {
            path: '/exchange-rates/international-dollar',
            name: 'International Dollar',
            component: () =>
              import(
                /* webpackChunkName: "exchange-rates-international-dollar" */ '../modules/exchange-rates-page/modules/international-dollar/views/ListInternationalDollarView.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'International Dollar',
              breadcrumbs_ancestry: [],
            },
          },
          {
            path: '/exchange-rates/international-dollar/create-new',
            name: 'CreateInternationalDollarNew',
            component: () =>
              import(
                /* webpackChunkName: "exchange-rates-international-dollar-create" */ '../modules/exchange-rates-page/modules/international-dollar/views/CreateInternationalDollarNew.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Create International Dollar Rates',
              breadcrumbs_ancestry: ['/exchange-rates/international-dollar'],
            },
          },
          {
            path: '/exchange-rates/international-dollar/edit',
            name: 'CreateInternationalDollarEdit',
            component: () =>
              import(
                /* webpackChunkName: "exchange-rates-international-dollar-create" */ '../modules/exchange-rates-page/modules/international-dollar/views/CreateInternationalDollarEdit.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Edit International Dollar Rates',
              breadcrumbs_ancestry: ['/exchange-rates/international-dollar'],
            },
          },
          {
            path: '/exchange-rates/international-dollar/view',
            name: 'CreateInternationalDollarView',
            component: () =>
              import(
                /* webpackChunkName: "exchange-rates-international-dollar-create" */ '../modules/exchange-rates-page/modules/international-dollar/views/CreateInternationalDollarView.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Create International Dollar Rates',
              breadcrumbs_ancestry: ['/exchange-rates/international-dollar'],
            },
          },
          {
            path: '/exchange-rates/international-dollar/view/:countryISO/:countryName',
            name: 'View International Dollar',
            component: () =>
              import(
                /* webpackChunkName: "exchange-rates-euro-daily" */ '../modules/exchange-rates-page/modules/international-dollar/views/ViewInternationalDollar.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'View International Dollar',
              breadcrumbs_ancestry: ['/exchange-rates/international-dollar'],
            },
          },
          {
            path: '/exchange-rates/international-dollar/edit/:countryISO/:countryName',
            name: 'Edit International Dollar',
            component: () =>
              import(
                /* webpackChunkName: "exchange-rates-euro-daily" */ '../modules/exchange-rates-page/modules/international-dollar/views/EditInternationalDollar.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Edit International Dollar',
              breadcrumbs_ancestry: ['/exchange-rates/international-dollar'],
            },
          },
          // {
          //   path: '/exchange-rates/international-dollar/test',
          //   name: 'Ivan Test',
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "exchange-rates-euro-daily" */ '../modules/exchange-rates-page/modules/international-dollar/views/IvansTest.vue'
          //     ),
          // },
          // {
          //   path: '/exchange-rates/international-dollar/test/:year_id',
          //   name: 'Ivan Test 2',
          //   component: () =>
          //     import(
          //       /* webpackChunkName: "exchange-rates-euro-daily" */ '../modules/exchange-rates-page/modules/international-dollar/views/IvansTest2.vue'
          //     ),
          // },
        ],
      },
      // /settings
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('../modules/settings-page/views/IndexSettingsView.vue'),
        props: {
          breadcrumb_name: 'Settings',
          breadcrumbs_ancestry: [],
        },
        redirect: {
          name: 'Profile',
        },
        children: [
          {
            path: 'user-data',
            name: 'Profile',
            component: () => import('../modules/settings-page/components/UserDataComponent.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: 'local-settings',
            name: 'Local Settings',
            component: () => import('../modules/settings-page/components/LocalSettingsComponent.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: 'password',
            name: 'Password',
            component: () => import('../modules/settings-page/components/PasswordComponent.vue'),
            beforeEnter: [checkPermissions],
          },
        ],
      },
      // /system-access
      {
        path: '/system-access',
        name: 'System Access',
        component: () => import('../modules/system-access-page/views/IndexSystemAccessView.vue'),
        props: {
          breadcrumb_name: 'System Access',
          breadcrumbs_ancestry: [],
        },
        redirect: {
          name: 'User Permissions Table',
        },
        children: [
          {
            path: '/system-access/user-permissions',
            name: 'User Permissions',
            component: () =>
              import('../modules/system-access-page/modules/user-permissions-page/views/IndexUserPermissionsView.vue'),
            redirect: {
              name: 'User Permissions Table',
            },
            children: [
              {
                path: '/system-access/user-permissions/table',
                name: 'User Permissions Table',
                component: () =>
                  import('../modules/system-access-page/modules/user-permissions-page/views/ListUserPermissionsView.vue'),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'User Permissions',
                  breadcrumbs_ancestry: ['/system-access'],
                },
              },
              {
                path: '/system-access/user-permissions/read',
                name: 'User Permissions Read',
                component: () =>
                  import('../modules/system-access-page/modules/user-permissions-page/views/IndexUserPermissionsView.vue'),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: '1',
                  breadcrumbs_ancestry: ['/system-access', '/system-access/user-permissions/table'],
                },
              },
              {
                path: '/system-access/user-permissions/edit/:id',
                name: 'EditUserPermission',
                component: () =>
                  import('../modules/system-access-page/modules/user-permissions-page/views/EditUserPermissionsView.vue'),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'Edit',
                  breadcrumbs_ancestry: [
                    '/system-access',
                    '/system-access/user-permissions/table',
                    '/system-access/user-permissions/read',
                  ],
                },
              },
              {
                path: '/system-access/user-permissions/edit/:id',
                name: 'User Permissions Edit',
                component: () =>
                  import('../modules/system-access-page/modules/user-permissions-page/views/EditUserPermissionsView.vue'),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'Edit',
                  breadcrumbs_ancestry: [
                    '/system-access',
                    '/system-access/user-permissions/table',
                    '/system-access/user-permissions/read',
                  ],
                },
              },
              {
                path: '/system-access/user-permissions/create',
                name: 'User Permissions Create',
                component: () =>
                  import('../modules/system-access-page/modules/user-permissions-page/views/CreateUserPermissionsView.vue'),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'Create',
                  breadcrumbs_ancestry: ['/system-access', '/system-access/user-permissions/table'],
                },
              },
            ],
          },
          {
            path: '/system-access/roles',
            name: 'System Roles',
            component: () =>
              import('../modules/system-access-page/modules/system-roles-page/views/IndexRolesPermissionsView.vue'),
            redirect: {
              name: 'System Roles Table',
            },
            children: [
              {
                path: '/system-access/system-roles/table',
                name: 'System Roles Table',
                component: () =>
                  import('../modules/system-access-page/modules/system-roles-page/views/ListRolesPermissionsView.vue'),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'Roles',
                  breadcrumbs_ancestry: ['/system-access'],
                },
              },
              {
                path: '/system-access/system-roles/read',
                name: 'System Roles Read',
                component: () =>
                  import('../modules/system-access-page/modules/system-roles-page/views/IndexRolesPermissionsView.vue'),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: '1',
                  breadcrumbs_ancestry: ['/system-access', '/system-access/system-roles/table'],
                },
              },
              {
                path: '/system-access/system-roles/edit/:id',
                name: 'System Roles Edit',
                component: () =>
                  import('../modules/system-access-page/modules/system-roles-page/views/EditRolesPermissionsView.vue'),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'Edit',
                  breadcrumbs_ancestry: [
                    '/system-access',
                    '/system-access/system-roles/table',
                    '/system-access/system-roles/read',
                  ],
                },
              },
              {
                path: '/system-access/system-roles/create',
                name: 'System Roles Create',
                component: () =>
                  import('../modules/system-access-page/modules/system-roles-page/views/CreateRolesPermissionsView.vue'),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'Create',
                  breadcrumbs_ancestry: ['/system-access', '/system-access/system-roles/table'],
                },
              },
            ],
          },
          {
            path: '/system-access/system-permissions',
            name: 'System Permissions',
            component: () =>
              import('../modules/system-access-page/modules/system-permissions-page/views/IndexSystemPermissionsView.vue'),
            redirect: {
              name: 'System Permissions Table',
            },
            children: [
              {
                path: '/system-access/system-permissions/table',
                name: 'System Permissions Table',
                component: () =>
                  import('../modules/system-access-page/modules/system-permissions-page/views/ListSystemPermissionsView.vue'),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'System Permissions',
                  breadcrumbs_ancestry: ['/system-access'],
                },
              },
              {
                path: '/system-access/system-permissions/edit/:id',
                name: 'EditSystemPermission',
                component: () =>
                  import('../modules/system-access-page/modules/system-permissions-page/views/EditSystemPermissionsView.vue'),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'Edit',
                  breadcrumbs_ancestry: ['/system-access', '/system-access/system-permissions/table'],
                },
              },
              {
                path: '/system-access/system-permissions/edit',
                name: 'System Permissions Edit',
                component: () =>
                  import('../modules/system-access-page/modules/system-permissions-page/views/EditSystemPermissionsView.vue'),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'Edit',
                  breadcrumbs_ancestry: ['/system-access', '/system-access/system-permissions/table'],
                },
              },
              {
                path: '/system-access/system-permissions/create',
                name: 'System Permissions Create',
                component: () =>
                  import('../modules/system-access-page/modules/system-permissions-page/views/CreateSystemPermissionsView.vue'),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'Create',
                  breadcrumbs_ancestry: ['/system-access', '/system-access/system-permissions/table'],
                },
              },
              {
                path: '/system-access/debugger',
                name: 'SystemAccessDebuggerIndex',
                component: () => import('../modules/system-access-page/modules/debugger/views/IndexPage.vue'),
                beforeEnter: [checkPermissions],
                props: {
                  breadcrumb_name: 'Debugger',
                  breadcrumbs_ancestry: ['/system-access'],
                },
              },
            ],
          },
        ],
      },
      // /reports
      {
        path: '/reports',
        name: 'Reports',
        redirect: '/reports/view',
        children: [
          {
            path: '/reports/view',
            name: 'Reports',
            component: () => import(/* webpackChunkName: "regions" */ '../modules/reports-page/view-reports.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/reports/edit/:id',
            name: 'Edit Report',
            component: () => import(/* webpackChunkName: "reports" */ '@/components/editor/reports/index2.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/reports/create-report/:id',
            name: 'Create Report',
            component: () => import(/* webpackChunkName: "regions" */ '@/components/editor/reports/index2.vue'),
            beforeEnter: [checkPermissions],
          },
        ],
      },
      // /administration
      {
        path: '/administration',
        name: 'Administration',
        component: () =>
          import(/* webpackChunkName: "exchange rates" */ '../modules/administration-page/views/IndexAdministrationView.vue'),
        props: {
          breadcrumb_name: 'Administration',
          breadcrumbs_ancestry: [],
        },
        redirect: {
          path: '#',
        },
        children: [
          {
            path: '/administration/system-pages',
            name: 'System Pages',
            component: () => import(/* webpackChunkName: "create" */ '../modules/administration-page/views/SystemPageView.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'System Pages',
              breadcrumbs_ancestry: ['/administration'],
            },
          },
          {
            path: '/administration/system-pages/new',
            name: 'CreateSystemPage',
            component: () =>
              import(/* webpackChunkName: "create" */ '../modules/administration-page/views/SystemPageEditor.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Create Page',
              breadcrumbs_ancestry: ['/administration/system-pages'],
            },
          },
          {
            path: '/administration/system-pages/:id',
            name: 'EditSystemPage',
            component: () =>
              import(/* webpackChunkName: "create" */ '../modules/administration-page/views/SystemPageEditor.vue'),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Edit Page',
              breadcrumbs_ancestry: ['/administration/system-pages'],
            },
          },
          {
            path: '/administration/export-actions',
            name: 'Export Actions',
            component: () =>
              import(
                /* webpackChunkName: "exchange-rates-euro" */ '../modules/administration-page/views/ExportActionsView.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Export Actions',
              breadcrumbs_ancestry: ['/administration'],
            },
          },
          {
            path: '/administration/navigation',
            name: 'Navigation',
            component: () =>
              import(
                /* webpackChunkName: "exchange-rates-euro" */ '../modules/administration-page/views/NavigationPageView.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Navigation',
              breadcrumbs_ancestry: ['/administration'],
            },
          },
          {
            path: '/administration/flexview-manager',
            name: 'Flexview Manager',
            component: () =>
              import(
                /* webpackChunkName: "exchange-rates-euro" */ '../modules/administration-page/views/FlexviewManagerView.vue'
              ),
            beforeEnter: [checkPermissions],
            props: {
              breadcrumb_name: 'Flexview Manager',
              breadcrumbs_ancestry: ['/administration'],
            },
          },
        ],
      },

      {
        path: '/demo',
        name: 'Demo Page',
        component: () => import(/* webpackChunkName: "demoPage" */ '@/components/DemoPage.vue'),
      },
      {
        path: '/demo2',
        name: 'Demo Page 2',
        component: () => import(/* webpackChunkName: "demoPageMonthlyReporting" */ '@/components/DemoPageMonthlyReporting.vue'),
      },
      {
        path: '/search/:value',
        name: 'Search',
        component: () => import(/* webpackChunkName: "search" */ '../modules/global-search/views/ListGlobalSearchView.vue'),
        redirect: {
          name: 'GlobalSearch',
        },
        children: [
          {
            path: '/search/:value',
            name: 'GlobalSearch',
            component: () => import(/* webpackChunkName: "search" */ '../modules/global-search/views/ListGlobalSearchView.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/search/:type/:value',
            name: 'SearchType',
            component: () => import(/* webpackChunkName: "search" */ '@/components/list/global-search/TypeSearch.vue'),
            beforeEnter: [checkPermissions],
          },
          {
            path: '/search/:type/:value/:contact',
            name: 'SearchContactType',
            component: () => import(/* webpackChunkName: "search" */ '@/components/list/global-search/TypeSearch.vue'),
            beforeEnter: [checkPermissions],
          },
        ],
      },
      {
        path: '/notifications',
        name: 'Notifications',
        component: () => import(/* webpackChunkName: "notification" */ '@/components/list/notifications'),
        redirect: {
          name: 'NotificationsList',
        },
        children: [
          {
            path: '/notifications',
            name: 'NotificationsList',
            component: () => import(/* webpackChunkName: "notification" */ '@/components/list/notifications'),
            beforeEnter: [checkPermissions],
          },
        ],
      },
      {
        path: '/notification/:id',
        name: 'NotificationsView',
        component: () =>
          import(/* webpackChunkName: "notification" */ '@/components/list/notifications/NotificationRouter.vue'),
        beforeEnter: [checkPermissions],
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "js/chunks/login" */ '../modules/login-page/views/IndexLoginView.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: "js/chunks/login" */ '../modules/logout/index.vue'),
  },
  {
    path: '/password-reset/:token',
    name: 'password reset',
    component: () => import(/* webpackChunkName: "login" */ '../modules/login-page/views/ResetPassword.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  // carry on with route
  next()

  // check for meta data requirements
  checkURLAndApplyMetaData(to)
})

async function checkPermissions(to, from, next) {
  const isLoggedIn = AuthService.isLoggedIn();

  if (to.name === 'password reset') {
    next();
    return;
  }
  if (to.path !== '/login' && to.path !== 'login' && !isLoggedIn) {
    const loginpath = window.location.pathname;
    const loginsearch = window.location.search;
    next({ name: 'login', query: { from: loginpath, search: loginsearch } });
    return;
  } /*else if (isLoggedIn && to.path === '/login' || to.path === 'login') {
    next({ name: 'Summary' });
  }*/ else if (isLoggedIn && routeNames.includes(to.name.toString())) {
    if (Object.prototype.hasOwnProperty.call(to.params, 'regionID')) {
      if (await checkRegionID(to.params.regionID, next)) {
        return;
      }
    }
    if (Object.prototype.hasOwnProperty.call(to.params, 'region_id')) {
      if (await checkRegionID(to.params.region_id, next)) {
        return;
      }
    }
    if (Object.prototype.hasOwnProperty.call(to.params, 'office_id')) {
      if (await checkOfficeID(to.params.office_id, next)) {
        return;
      }
    }
    if (Object.prototype.hasOwnProperty.call(to.params, 'company_id')) {
      if (await checkCompanyID(to.params.company_id, next)) {
        return;
      }
    }
    if (Object.prototype.hasOwnProperty.call(to.params, 'team_id')) {
      if (await checkTeamID(to.params.team_id, next)) {
        return;
      }
    }
    if (Object.prototype.hasOwnProperty.call(to.params, 'officeGroupID')) {
      if (Number(to.params.officeGroupID) !== 1) {
        if (await checkOfficeGroupID(to.params.officeGroupID, next)) {
          return;
        }
      }
    }
  }
  // if (to.query.section == undefined) to.query.section = '';

  if (!(await routerPermission(`${to.name as string}`, `${(to.query.section as string) || ''}`, next))) {
    return;
  }
}

async function checkRegionID(regionID, next): Promise<boolean> {
  let redirect = false;
  try {
    const res = await BaseService.checkRegionPermission(regionID);
    if (!res.data.success) {
      next({ name: 'Landing Page' });
      redirect = true;
    }
  } catch {
    next({ name: 'Landing Page' });
    redirect = true;
  }
  return redirect;
}

async function checkOfficeID(officeID, next): Promise<boolean> {
  let redirect = false;
  try {
    const res = await BaseService.get(`office/${officeID}`);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (res.status) {
      if (Number(userInfo.regionID) === 2) {
        /** */
      } else {
        if (Number(userInfo.regionID) !== Number(res.data.regionID)) {
          next({ name: 'Landing Page' });
          redirect = true;
        }
      }
    }
  } catch (e) {
    next({ name: 'Landing Page' });
    redirect = true;
  }
  return redirect;
}

async function checkTeamID(teamID, next): Promise<boolean> {
  let redirect = false;
  try {
    const teamRes = await BaseService.get(`team/${teamID}`);
    console.log(teamRes);
    if (teamRes.data.result == undefined) {
      teamRes.data.result = teamRes.data;
    }
    const res = await BaseService.get(`office/${teamRes.data.result.officeID}`);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (res.status) {
      if (Number(userInfo.regionID) === 2) {
        /** */
      } else {
        if (Number(userInfo.regionID) !== Number(res.data.regionID)) {
          next({ name: 'Landing Page' });
          redirect = true;
        }
      }
    }
  } catch (e) {
    next({ name: 'Landing Page' });
    redirect = true;
  }
  return redirect;
}

async function checkOfficeGroupID(officeGroupID, next): Promise<boolean> {
  let redirect = false;
  try {
    const res = await BaseService.get(`group/${officeGroupID}`);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (res.status) {
      if (Number(userInfo.regionID) === 2) {
        /** */
      } else {
        if (Number(userInfo.regionID) !== Number(res.data.regionID)) {
          next({ name: 'Landing Page' });
          redirect = true;
        }
      }
    }
  } catch (e) {
    next({ name: 'Landing Page' });
    redirect = true;
  }
  return redirect;
}

async function checkCompanyID(companyID, next): Promise<boolean> {
  let redirect = false;
  try {
    const res = await BaseService.get(`company/${companyID}`);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (res.status) {
      if (Number(userInfo.regionID) === 2) {
        /** */
      } else {
        if (Number(userInfo.regionID) !== Number(res.data.regionID)) {
          next({ name: 'Landing Page' });
          redirect = true;
        }
      }
    }
  } catch (e) {
    next({ name: 'Landing Page' });
    redirect = true;
  }
  return redirect;
}

async function routerPermission(pageBeingAccessed, query, next): Promise<boolean> {
  if (!store.state.auth.authenticated.session) {
    const authState = await store.dispatch('auth/loadAuthenticatedUserData');
    if (!authState) {
      next({ name: 'Login' });
      return false;
    }
  }
  const permissionsArray = computed(() => Object.keys(store.state.auth.authenticated.session.permissions));
  let rowEditButton;
  let res;
  switch (pageBeingAccessed) {
    case 'Regions':
      if (!permissionsArray.value.includes('REGION')) {
        next({ name: 'Dashboard' });
      } else {
        next();
      }
      return;
      break;

    case 'Create Region':
      if (!permissionsArray.value.includes('REGION_CREATE')) {
        next({ name: 'Regions' });
      } else {
        next();
        return;
      }
      break;

    case 'Read Region':
      if (!permissionsArray.value.includes('REGION')) {
        next({ name: 'Regions' });
      } else {
        //check tab permissions
        if (query != '') {
          switch (query) {
            case 'general':
              if (permissionsArray.value.includes('REGION_GENERAL_READ')) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;
            case 'business-plan':
              if (permissionsArray.value.includes('REGION_BUSINESS_PLAN_READ')) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;

            case 'fees':
              if (permissionsArray.value.includes('REGION_FEES_READ') || permissionsArray.value.includes('REGION_RFA_READ')) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;

            case 'perfomance-schedule':
              if (permissionsArray.value.includes('REGION_PERFORMANCE_SCHEDULES_READ')) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;

            case 'development-schedule':
              if (permissionsArray.value.includes('REGION_PERFORMANCE_SCHEDULES_READ')) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;

            case 'fee-reliefs':
              if (permissionsArray.value.includes('REGION_FEES_READ') || permissionsArray.value.includes('REGION_RFA_READ')) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;

            case 'notes':
              if (permissionsArray.value.includes('REGION_NOTES_READ')) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;

            case 'change-history':
              if (permissionsArray.value.includes('REGION_NOTES_AND_HISTORY_READ')) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;
          }
        } else {
          next();
        }
      }
      break;

    case 'Edit Region':
      rowEditButton = regionConstants.EDIT_PERMISSIONS;
      res = rowEditButton.some((value) => {
        return permissionsArray.value.indexOf(value) !== -1;
      });
      if (!res) {
        next({ name: 'Regions' });
      } else {
        if (query != '') {
          switch (query) {
            case 'general':
              if (permissionsArray.value.includes('REGION_GENERAL_UPDATE')) {
                next();
                return;
              } else {
                next();
                return;
              }
              break;

            case 'business-plan':
              if (permissionsArray.value.includes('REGION_BUSINESS_PLAN_UPDATE')) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;

            case 'fees':
              if (
                permissionsArray.value.includes('REGION_FEES_UPDATE') ||
                permissionsArray.value.includes('REGION_RFA_UPDATE') ||
                permissionsArray.value.includes('REGION_RFA_READ') ||
                permissionsArray.value.includes('REGION_FEES_READ')
              ) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;

            case 'perfomance-schedule':
              if (
                permissionsArray.value.includes('REGION_PERFORMANCE_SCHEDULES_UPDATE') ||
                permissionsArray.value.includes('REGION_PERFORMANCE_SCHEDULES_READ')
              ) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;

            case 'development-schedule':
              if (
                permissionsArray.value.includes('REGION_PERFORMANCE_SCHEDULES_UPDATE') ||
                permissionsArray.value.includes('REGION_PERFORMANCE_SCHEDULES_READ')
              ) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;

            case 'fee-reliefs':
              if (
                permissionsArray.value.includes('REGION_FEES_UPDATE') ||
                permissionsArray.value.includes('REGION_RFA_UPDATE') ||
                permissionsArray.value.includes('REGION_FEES_READ') ||
                permissionsArray.value.includes('REGION_RFA_READ')
              ) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;

            case 'notes':
              if (permissionsArray.value.includes('REGION_NOTES_AND_HISTORY_UPDATE')) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;

            case 'change-history':
              if (permissionsArray.value.includes('REGION_NOTES_AND_HISTORY_UPDATE')) {
                next();
                return;
              } else {
                next({ name: 'Regions' });
              }
              break;
          }
        } else {
          next();
        }
      }
      break;

    //Offices - if no create/edit permissions redirect to offices table
    case 'Office Table':
      if (!permissionsArray.value.includes('OFFICE')) {
        next({ name: 'Dashboard' });
      } else {
        next();
        return;
      }
      break;

    case 'Create Office':
      if (!permissionsArray.value.includes('OFFICE_CREATE') || !permissionsArray.value.includes('OFFICE_REQUEST_CREATE')) {
        next({ name: 'Office Table' });
      } else {
        next();
        return;
      }
      break;

    case 'ViewOffice':
      if (!permissionsArray.value.includes('OFFICE')) {
        next({ name: 'Office Table' });
      } else {
        //check tab permissions
        if (query != '') {
          switch (query) {
            case 'general':
              if (
                permissionsArray.value.includes('OFFICE_ADDRESS_READ') ||
                permissionsArray.value.includes('OFFICE_ADDRESS_UPDATE') ||
                permissionsArray.value.includes('OFFICE_CONTACT_READ') ||
                permissionsArray.value.includes('OFFICE_CONTACT_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'contact-information':
              if (
                permissionsArray.value.includes('OFFICE_ADDRESS_READ') ||
                permissionsArray.value.includes('OFFICE_ADDRESS_UPDATE') ||
                permissionsArray.value.includes('OFFICE_CONTACT_READ') ||
                permissionsArray.value.includes('OFFICE_CONTACT_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'persons':
              if (
                permissionsArray.value.includes('OFFICE_PERSONS_READ') ||
                permissionsArray.value.includes('OFFICE_PERSONS_REQUEST_UPDATE') ||
                permissionsArray.value.includes('OFFICE_PERSONS_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'office-group':
              if (
                permissionsArray.value.includes('OFFICE_MULTI_BROKERAGE_READ') ||
                permissionsArray.value.includes('OFFICE_MULTI_BROKERAGE_REQUEST_UPDATE') ||
                permissionsArray.value.includes('OFFICE_MULTI_BROKERAGE_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'teams':
              if (permissionsArray.value.includes('OFFICE_TEAM_READ')) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'ownership':
              if (
                permissionsArray.value.includes('OFFICE_OWNERSHIP_READ') ||
                permissionsArray.value.includes('OFFICE_OWNERSHIP_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'franchise':
              if (
                permissionsArray.value.includes('OFFICE_OWNERSHIP_READ') ||
                permissionsArray.value.includes('OFFICE_OWNERSHIP_UPDATE') ||
                permissionsArray.value.includes('OFFICE_OWNERSHIP_REQUEST_UPDATE') ||
                permissionsArray.value.includes('OFFICE_FINANCE_READ') ||
                permissionsArray.value.includes('OFFICE_FINANCE_UPDATE') ||
                permissionsArray.value.includes('OFFICE_FINANCE_REQUEST_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'development-schedule':
              if (
                permissionsArray.value.includes('OFFICE_DEVSCHED_READ') ||
                permissionsArray.value.includes('OFFICE_DEVSCHED_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'notes':
              if (
                permissionsArray.value.includes('OFFICE_NOTES_READ') ||
                permissionsArray.value.includes('OFFICE_NOTES_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'change-history':
              if (permissionsArray.value.includes('OFFICE_HISTORY_READ')) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;
          }
        } else {
          next();
        }
      }
      break;

    case 'EditOffice':
      rowEditButton = officeConstants.EDIT_PERMISSIONS;

      res = rowEditButton.some((value) => {
        return permissionsArray.value.indexOf(value) !== -1;
      });
      if (!res) {
        next({ name: 'Office Table' });
      } else {
        //check tab permissions
        if (query != '') {
          switch (query) {
            case 'general':
              if (
                permissionsArray.value.includes('OFFICE_ADDRESS_READ') ||
                permissionsArray.value.includes('OFFICE_ADDRESS_UPDATE') ||
                permissionsArray.value.includes('OFFICE_CONTACT_READ') ||
                permissionsArray.value.includes('OFFICE_CONTACT_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'contact-information':
              if (
                permissionsArray.value.includes('OFFICE_ADDRESS_READ') ||
                permissionsArray.value.includes('OFFICE_ADDRESS_UPDATE') ||
                permissionsArray.value.includes('OFFICE_CONTACT_READ') ||
                permissionsArray.value.includes('OFFICE_CONTACT_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'persons':
              if (
                permissionsArray.value.includes('OFFICE_PERSONS_READ') ||
                permissionsArray.value.includes('OFFICE_PERSONS_REQUEST_UPDATE') ||
                permissionsArray.value.includes('OFFICE_PERSONS_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'office-group':
              if (
                permissionsArray.value.includes('OFFICE_MULTI_BROKERAGE_READ') ||
                permissionsArray.value.includes('OFFICE_MULTI_BROKERAGE_REQUEST_UPDATE') ||
                permissionsArray.value.includes('OFFICE_MULTI_BROKERAGE_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'teams':
              if (permissionsArray.value.includes('OFFICE_TEAM_READ')) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'ownership':
              if (
                permissionsArray.value.includes('OFFICE_OWNERSHIP_READ') ||
                permissionsArray.value.includes('OFFICE_OWNERSHIP_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'franchise':
              if (
                permissionsArray.value.includes('OFFICE_OWNERSHIP_READ') ||
                permissionsArray.value.includes('OFFICE_OWNERSHIP_UPDATE') ||
                permissionsArray.value.includes('OFFICE_OWNERSHIP_REQUEST_UPDATE') ||
                permissionsArray.value.includes('OFFICE_FINANCE_READ') ||
                permissionsArray.value.includes('OFFICE_FINANCE_UPDATE') ||
                permissionsArray.value.includes('OFFICE_FINANCE_REQUEST_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'development-schedule':
              if (
                permissionsArray.value.includes('OFFICE_DEVSCHED_READ') ||
                permissionsArray.value.includes('OFFICE_DEVSCHED_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'notes':
              if (
                permissionsArray.value.includes('OFFICE_NOTES_READ') ||
                permissionsArray.value.includes('OFFICE_NOTES_UPDATE')
              ) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'change-history':
              if (permissionsArray.value.includes('OFFICE_HISTORY_READ')) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
              }
              break;
          }
        } else {
          next();
        }
      }
      break;

    //Company
    case 'Company Table':
      if (permissionsArray.value.includes('COMPANY')) {
        next();
        return;
      }
      next({ name: 'Dashboard' });
      return false;
      break;

    case 'Create Company':
      if (permissionsArray.value.includes('COMPANY_CREATE')) {
        next();
        return;
      }
      next({ name: 'Company Table' });
      return false;
      break;

    case 'ReadCompany':
      if (!permissionsArray.value.includes('COMPANY_GENERAL_READ')) {
        next({ name: 'Company Table' });
        return false;
      } else {
        //check tab permissions
        if (query != '') {
          switch (query) {
            case 'general':
              next();
              return;
              break;

            case 'shareholders':
              if (permissionsArray.value.includes('COMPANY_OWNERSHIP_READ')) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
                return false;
              }
              break;

            case 'offices':
              if (permissionsArray.value.includes('COMPANY_OWNERSHIP_READ')) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
                return false;
              }
              break;

            case 'notes':
              if (permissionsArray.value.includes('COMPANY_NOTES_READ')) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
                return false;
              }
              break;

            case 'change-history':
              if (permissionsArray.value.includes('COMPANY_HISTORY_READ')) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
                return false;
              }
              break;
          }
        } else {
          next();
          return;
        }
      }
      next();
      return;
      break;

    case 'EditCompany':
      rowEditButton = ['COMPANY_UPDATE'];
      res = rowEditButton.some((value) => {
        return permissionsArray.value.indexOf(value) !== -1;
      });
      if (!res) {
        next({ name: 'Company Table' });
        return false;
      } else {
        //check tab permissions
        if (query != '') {
          switch (query) {
            case 'general':
              next();
              return;
              break;

            case 'shareholders':
              if (permissionsArray.value.includes('COMPANY_OWNERSHIP_READ')) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
                return false;
              }
              return;
              break;

            case 'offices':
              if (permissionsArray.value.includes('COMPANY_OWNERSHIP_READ')) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
                return false;
              }
              return;
              break;

            case 'notes':
              if (permissionsArray.value.includes('COMPANY_NOTES_READ')) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
                return false;
              }
              return;
              break;

            case 'change-history':
              if (permissionsArray.value.includes('COMPANY_HISTORY_READ')) {
                next();
                return;
              } else {
                next({ name: 'Office Table' });
                return false;
              }
              return;
              break;
          }
        } else {
          next();
          return;
        }
      }
      next();
      return;
      break;

    //Persons
    case 'Persons Table':
      if (!permissionsArray.value.includes('PERSON')) {
        next({ name: 'Dashboard' });
      } else {
        next();
        return;
      }
      break;

    case 'ViewPerson':
      if (!permissionsArray.value.includes('PERSON_ADVANCED_DATA_READ')) {
        next({ name: 'Dashboard' });
      } else {
        //check tab permissions
        if (query != '') {
          switch (query) {
            case 'general':
              if (permissionsArray.value.includes('PERSON_GENERAL_READ')) {
                next();
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'contact-information':
              if (permissionsArray.value.includes('PERSON_GENERAL_READ')) {
                next();
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'remax-titles':
              if (permissionsArray.value.includes('PERSON_TITLES_AND_TEAMS_READ')) {
                next();
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'team':
              if (permissionsArray.value.includes('PERSON_GENERAL_READ')) {
                next();
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'performance':
              if (permissionsArray.value.includes('PERSON_GENERAL_READ')) {
                next();
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'offices-owned':
              if (permissionsArray.value.includes('PERSON_GENERAL_READ')) {
                next();
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'system-access':
              if (permissionsArray.value.includes('PERSON_SYSTEM_ACCESS_READ')) {
                next();
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'change-history':
              if (permissionsArray.value.includes('PERSON_HISTORY_READ')) {
                next();
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'notes':
              if (permissionsArray.value.includes('PERSON_NOTES_READ')) {
                next();
              } else {
                next({ name: 'Office Table' });
              }
              break;
          }
        } else {
          next();
        }
      }
      break;

    case 'CreatePerson':
      if (!permissionsArray.value.includes('PERSON_CREATE')) {
        next({ name: 'Persons' });
      } else {
        next();
        return;
      }
      break;

    case 'EditPerson':
      rowEditButton = ['PERSON_ADVANCED_DATA_UPDATE'];
      res = rowEditButton.some((value) => {
        return permissionsArray.value.indexOf(value) !== -1;
      });
      if (!res) {
        next({ name: 'Persons' });
      } else {
        //check tab permissions
        if (query != '') {
          switch (query) {
            case 'general':
              next();
              break;

            case 'shareholders':
              if (permissionsArray.value.includes('COMPANY_OWNERSHIP_READ')) {
                next();
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'offices':
              if (permissionsArray.value.includes('COMPANY_OWNERSHIP_READ')) {
                next();
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'notes':
              if (permissionsArray.value.includes('COMPANY_NOTES_READ')) {
                next();
              } else {
                next({ name: 'Office Table' });
              }
              break;

            case 'change-history':
              if (permissionsArray.value.includes('COMPANY_HISTORY_READ')) {
                next();
              } else {
                next({ name: 'Office Table' });
              }
              break;

            default:
              next();
          }
        } else {
          next();
        }
      }
      break;

    //Monthly Reporting
    case 'Monthly Reporting':
      if (!permissionsArray.value.includes('REPORTING')) {
        next({ name: 'Landing Page' });
        return false;
      } else {
        next();
        return;
      }
      break;

    case 'Monthly Reporting - Regions':
      if (!permissionsArray.value.includes('REPORTING_GENERAL_READ')) {
        next({ name: 'Landing Page' });
        return false;
      } else {
        next();
        return;
      }
      break;

    case 'MonthlyReportingRegionsReadMonths':
      if (!permissionsArray.value.includes('REPORTING_GENERAL_READ')) {
        next({ name: 'Landing Page' });
        return false;
      } else {
        next();
        return;
      }
      break;

    case 'MonthlyReporting-Regions-Read-Months-Read-Offices':
      if (!permissionsArray.value.includes('REPORTING_GENERAL_READ')) {
        next({ name: 'Landing Page' });
        return false;
      } else {
        next();
        return;
      }
      break;

    case 'MonthlyReporting-Regions-Read-Months-Read-Offices-Edit':
      rowEditButton = ['REPORTING_GENERAL_REQUEST_UPDATE', 'REPORTING_GENERAL_UPDATE'];
      res = rowEditButton.some((value) => {
        return permissionsArray.value.indexOf(value) !== -1;
      });
      if (!res) {
        next({ name: 'Landing Page' });
        return false;
      } else {
        next();
        return;
      }
      break;

    //Annual Dues - Fee Relief
    case 'Fee Relief Table':
      if (!permissionsArray.value.includes('FEE_RELIEF')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    case 'ViewFeeRelief':
      if (!permissionsArray.value.includes('FEE_RELIEF_READ')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    case 'Create Fee Relief':
      if (!permissionsArray.value.includes('FEE_RELIEF_CREATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    case 'EditFeeRelief':
      rowEditButton = ['FEE_RELIEF_UPDATE'];
      res = rowEditButton.some((value) => {
        return permissionsArray.value.indexOf(value) !== -1;
      });
      if (!res) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    case 'AnnualDuesReportsAddFinancePayment':
      if (!permissionsArray.value.includes('ANNUAL_DUES_FINANCE_UPDATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    //Annual Dues - Reports
    case 'AnnualDuesReportsOverview':
      if (!permissionsArray.value.includes('ANNUAL_DUES')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    case 'AnnualDuesReportsCurrent':
      if (!permissionsArray.value.includes('ANNUAL_DUES')) {
        next({ name: 'Landing Page' });
      } else {
        //check tab permissions
        if (query != '') {
          switch (query) {
            case 'general':
              next();
              break;

            case 'change-history':
              if (permissionsArray.value.includes('ANNUAL_DUES_HISTORY_READ')) {
                next();
                return;
              } else {
                next({ name: 'Landing Page' });
              }
              break;
          }
        } else {
          next();
        }
      }
      break;

    case 'AnnualDuesReportsMonthlyOverview':
      if (!permissionsArray.value.includes('ANNUAL_DUES')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    case 'AnnualDuesReportsFinancialOverview':
      if (!permissionsArray.value.includes('ANNUAL_DUES_FINANCE_READ')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    case 'AnnualDuesReportsEditFinancePayment':
      rowEditButton = ['ANNUAL_DUES_UPDATE'];
      res = rowEditButton.some((value) => {
        return permissionsArray.value.indexOf(value) !== -1;
      });
      if (!res) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    //Reports
    case 'Reports':
      if (!permissionsArray.value.includes('REPORTS')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    case 'Edit Report - Agents':
      if (!permissionsArray.value.includes('REPORTS')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    case 'Create Report':
      if (!permissionsArray.value.includes('REPORTS')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    //Awards
    case 'Awards':
      if (!permissionsArray.value.includes('AWARD')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    //Teams
    case 'Teams Table':
      if (!permissionsArray.value.includes('TEAM')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    case 'CreateTeam':
      if (!permissionsArray.value.includes('TEAM_CREATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    case 'EditTeam':
      rowEditButton = ['TEAM_UPDATE', 'TEAM_REQUEST_UPDATE'];
      res = rowEditButton.some((value) => {
        return permissionsArray.value.indexOf(value) !== -1;
      });
      if (!res) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    case 'ViewTeam':
      if (!permissionsArray.value.includes('TEAM_GENERAL_READ')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    //Office Groups
    case 'Office Groups Table':
      if (!permissionsArray.value.includes('MULTI_BROKERAGE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;

    case 'Create Office Group':
      if (!permissionsArray.value.includes('MULTI_BROKERAGE_CREATE')) {
        next({ name: 'Office Groups' });
      } else {
        next();
        return;
      }
      break;

    case 'View Office Groups':
      if (!permissionsArray.value.includes('MULTI_BROKERAGE_GENERAL_READ')) {
        next({ name: 'Office Groups' });
      } else {
        next();
        return;
      }
      break;

    case 'View Ungrouped Offices':
      if (!permissionsArray.value.includes('MULTI_BROKERAGE_GENERAL_READ')) {
        next({ name: 'Office Groups' });
      } else {
        next();
        return;
      }
      break;

    case 'Edit Office Groups':
      rowEditButton = ['MULTI_BROKERAGE_UPDATE', 'MULTI_BROKERAGE_REQUEST_UPDATE'];
      res = rowEditButton.some((value) => {
        return permissionsArray.value.indexOf(value) !== -1;
      });
      if (!res) {
        next({ name: 'Office Groups' });
      } else {
        next();
        return;
      }
      break;

    case 'Edit Ungrouped Offices':
      rowEditButton = ['MULTI_BROKERAGE_UPDATE', 'MULTI_BROKERAGE_REQUEST_UPDATE'];
      res = rowEditButton.some((value) => {
        return permissionsArray.value.indexOf(value) !== -1;
      });
      if (!res) {
        next({ name: 'Office Groups' });
      } else {
        next();
        return;
      }
      break;

    //Contact Mgmt
    //Contacts
    case 'Contacts List':
      if (!permissionsArray.value.includes('CONTACT_MANAGEMENT')) {
        next({ name: 'Dashboard' });
      } else {
        next();
        return;
      }
      break;

    case 'CreateContact':
      if (!permissionsArray.value.includes('CONTACT_MANAGEMENT_CREATE')) {
        next({ name: 'Contacts List' });
      } else {
        next();
        return;
      }
      break;

    case 'EditContact':
      rowEditButton = ['CONTACT_MANAGEMENT_UPDATE', 'CONTACT_MANAGEMENT_REQUEST_UPDATE'];
      res = rowEditButton.some((value) => {
        return permissionsArray.value.indexOf(value) !== -1;
      });
      if (!res) {
        next({ name: 'Contacts List' });
      } else {
        next();
        return;
      }
      break;

    case 'ViewContact':
      if (!permissionsArray.value.includes('CONTACT_MANAGEMENT_READ')) {
        next({ name: 'Contacts List' });
      } else {
        //check tab permissions
        if (query != '') {
          switch (query) {
            case 'general':
              next();
              break;

            case 'change-history':
              if (permissionsArray.value.includes('CONTACT_MANAGEMENT_HISTORY_READ')) {
                next();
              } else {
                next({ name: 'Office Table' });
              }
              break;
          }
        } else {
          next();
        }
      }
      break;

    //Sync Log
    case 'SyncLogList':
      if (!permissionsArray.value.includes('CONTACT_MANAGEMENT_LOG')) {
        next({ name: 'Dashboard' });
      } else {
        next();
        return;
      }
      break;

    case 'ViewSyncLog':
      if (!permissionsArray.value.includes('CONTACT_MANAGEMENT_LOG_READ')) {
        next({ name: 'Sync Log' });
      } else {
        next();
        return;
      }
      break;

    //Exchange Rates
    //Euro
    case 'Exchange Rates':
      if (!permissionsArray.value.includes('EXCHANGE_RATE')) {
        next({ name: 'Dashboard' });
      } else {
        next();
        return;
      }
      break;

    case 'Exchange Rates - Euro - Months':
      if (!permissionsArray.value.includes('EXCHANGE_RATE')) {
        next({ name: 'Dashboard' });
      } else {
        next();
        return;
      }
      break;

    case 'Exchange Rates - Euro - Months - Read - Exchange Rates':
      if (!permissionsArray.value.includes('EXCHANGE_RATE_GENERAL_READ')) {
        next({ name: 'Exchange Rates' });
      } else {
        next();
        return;
      }
      break;

    case 'Days':
      if (!permissionsArray.value.includes('EXCHANGE_RATE_DAILY_READ')) {
        next({ name: 'Dashboard' });
      } else {
        next();
        return;
      }
      break;

    //International Dollar
    case 'International Dollar':
      if (!permissionsArray.value.includes('EXCHANGE_RATE_RXD')) {
        next({ name: 'Dashboard' });
      } else {
        next();
        return;
      }
      break;

    case 'View International Dollar':
      if (!permissionsArray.value.includes('EXCHANGE_RATE_RXD_GENERAL_READ')) {
        next({ name: 'International Dollar' });
      } else {
        next();
        return;
      }
      break;

    case 'Edit International Dollar':
      rowEditButton = ['EXCHANGE_RATE_RXD_GENERAL_UPDATE', 'EXCHANGE_RATE_RXD_GENERAL_REQUEST_UPDATE'];
      res = rowEditButton.some((value) => {
        return permissionsArray.value.indexOf(value) !== -1;
      });
      if (!res) {
        next({ name: 'International Dollar' });
      } else {
        next();
        return;
      }
      break;

    case 'CreateInternationalDollarNew':
      if (!permissionsArray.value.includes('EXCHANGE_RATE_RXD_CREATE')) {
        next({ name: 'International Dollar' });
      } else {
        next();
        return;
      }
      break;

    case 'CreateInternationalDollarEdit':
      if (!permissionsArray.value.includes('EXCHANGE_RATE_RXD_CREATE')) {
        next({ name: 'International Dollar' });
      } else {
        next();
        return;
      }
      break;

    case 'CreateInternationalDollarView':
      if (!permissionsArray.value.includes('EXCHANGE_RATE_RXD_CREATE')) {
        next({ name: 'International Dollar' });
      } else {
        next();
        return;
      }
      break;

    //My settings
    case 'Settings':
      if (!permissionsArray.value.includes('MY_PERSONAL_DATA')) {
        next({ name: 'Dashboard' });
      } else {
        next();
        return;
      }
      break;

    case 'Profile':
      if (!permissionsArray.value.includes('MY_PERSONAL_DATA')) {
        next({ name: 'Dashboard' });
      } else {
        next();
        return;
      }
      break;

    case 'User Permissions Table':
      if (!permissionsArray.value.includes('ACCESS_MANAGEMENT')) {
        next({ name: 'Dashboard' });
      } else {
        next();
        return;
      }
      break;

    case 'User Permissions Read':
      if (!permissionsArray.value.includes('ACCESS_MANAGEMENT_GENERAL_READ')) {
        next({ name: 'User Permissions Table' });
      } else {
        next();
        return;
      }
      break;

    case 'EditUserPermission':
      if (!permissionsArray.value.includes('ACCESS_MANAGEMENT_GENERAL_UPDATE')) {
        next({ name: 'User Permissions Table' });
      } else {
        next();
        return;
      }
      break;

    case 'User Permissions Edit':
      if (!permissionsArray.value.includes('ACCESS_MANAGEMENT_GENERAL_UPDATE')) {
        next({ name: 'User Permissions Table' });
      } else {
        next();
        return;
      }
      break;

    case 'User Permissions Create':
      if (!permissionsArray.value.includes('ACCESS_MANAGEMENT_GENERAL_UPDATE')) {
        next({ name: 'User Permissions Table' });
      } else {
        next();
        return;
      }
      break;

    case 'System Roles Table':
      if (!permissionsArray.value.includes('ACCESS_MANAGEMENT')) {
        next({ name: 'Dashboard' });
      } else {
        next();
        return;
      }
      break;

    case 'System Roles Create':
      if (!permissionsArray.value.includes('ACCESS_MANAGEMENT')) {
        next({ name: 'System Roles Table' });
      } else {
        next();
        return;
      }
      break;

    case 'System Roles Read':
      if (!permissionsArray.value.includes('ACCESS_MANAGEMENT')) {
        next({ name: 'System Roles Table' });
        return false;
      } else {
        next();
        return;
      }
      break;

    case 'System Roles Edit':
      if (!permissionsArray.value.includes('ACCESS_MANAGEMENT')) {
        next({ name: 'System Roles Table' });
        return false;
      } else {
        next();
        return;
      }
      break;

    case 'Contracts':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT')) {
        next();
        return;
        //next({ name: 'Dashboard' });
      } else {
        next();
        return;
      }
      break;

    case 'NotificationsList':
      if (!permissionsArray.value.includes('NOTIFICATION')) {
        next({ name: 'Dashboard' });
      } else {
        next();
        return;
      }
      break;

    case 'NotificationsView':
      if (!permissionsArray.value.includes('NOTIFICATION_READ')) {
        next({ name: 'NotificationsList' });
      } else {
        next();
        return;
      }
      break;

    case 'Summary':
      if (!permissionsArray.value.includes('DASHBOARD_WIDGETS_GENERAL_READ')) {
        next({ name: 'Landing Page' });
        return false;
      } else {
        next();
        return;
      }
      break;

    case 'Office Overview':
      if (!permissionsArray.value.includes('DASHBOARD_WIDGETS_GENERAL_READ')) {
        next({ name: 'Landing Page' });
        return;
      } else {
        next();
        return;
      }
      break;

    case 'Office Dashboard':
      if (!permissionsArray.value.includes('DASHBOARD_WIDGETS_GENERAL_READ')) {
        next({ name: 'Landing Page' });
        return;
      } else {
        next();
        return;
      }
      break;

    case 'Recruitment':
      if (!permissionsArray.value.includes('DASHBOARD_WIDGETS_GENERAL_READ')) {
        next({ name: 'Landing Page' });
        return;
      } else {
        next();
        return;
      }
      break;

    case 'Data Glossary':
      if (!permissionsArray.value.includes('DASHBOARD_WIDGETS_GENERAL_READ')) {
        next({ name: 'Landing Page' });
        return false;
      } else {
        next();
        return;
      }
      break;
    case 'Franchise Sales':
      if (!permissionsArray.value.includes('DASHBOARD_WIDGETS_GENERAL_READ')) {
        next({ name: 'Landing Page' });
        return false;
      } else {
        next();
        return;
      }
      break;

    // case 'Create Contracts':
    // 	if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_SALE_CREATE')) {
    // 		next({ name: 'Landing Page' });
    // 	} else {
    // 		next();
    // 		return;
    // 	}
    // 	break;
    // case 'EditContract':
    // 	if (!permissionsArray.value.includes('DASHBOARD_WIDGETS_GENERAL_READ')) {
    // 		next({ name: 'Landing Page' });
    // 	} else {
    // 		next();
    // 		return;
    // 	}
    // 	break;
    case 'CreateSaleContractIndex':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_SALE_CREATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'CreateSaleContract':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_SALE_CREATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'EditSaleContractDraftIndex':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_SALE_CREATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'EditSaleContractDraft':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_SALE_CREATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'EditSaleContractIndex':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_UPDATE_CONTRACT')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'EditSaleContract':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_UPDATE_CONTRACT')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'ReviewSaleContract':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_REVIEW_CONTRACT')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'CreateRenewalContractIndex':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_RENEWAL_CREATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'CreateRenewalContract':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_RENEWAL_CREATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'EditRenewalContractDraftIndex':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_RENEWAL_CREATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'EditRenewalContractDraft':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_RENEWAL_CREATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'EditRenewalContractIndex':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_UPDATE_CONTRACT')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'EditRenewalContract':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_UPDATE_CONTRACT')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'ReviewRenewalContract':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_REVIEW_CONTRACT')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'CreateTransferContractIndex':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_TRANSFER_CREATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'CreateTransferContract':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_TRANSFER_CREATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'EditTransferContractDraftIndex':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_TRANSFER_CREATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'EditTransferContractDraft':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_TRANSFER_CREATE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'EditTransferContractIndex':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_UPDATE_CONTRACT')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'EditTransferContract':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_UPDATE_CONTRACT')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'ReviewTransferContract':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_REVIEW_CONTRACT')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'SavedForLater':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_SAVED_FOR_LATER')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'ContractsLog':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_LOGS')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    case 'FeeApprovalQueue':
      if (!permissionsArray.value.includes('CONTRACT_MANAGEMENT_FEE_APPROVAL_QUEUE')) {
        next({ name: 'Landing Page' });
      } else {
        next();
        return;
      }
      break;
    //No permissions required
    default:
      next();
      return;
      break;
  }
}

function checkURLAndApplyMetaData(to) {
  // make sure to only apply meta data to the routes the are required
  // decide what state to watch
  if (MetaDataRoutes.REQUIRED_ROUTES.includes(to.name)) {
    // initialize any required info need for all pages
    MetaDataRoutes.init(to);

    // OFFICE
    if (MetaDataRoutes.Office.required.includes(to.name)) return MetaDataRoutes.Office.Watch(to);

    // REGION
    if (MetaDataRoutes.Region.required.includes(to.name)) return MetaDataRoutes.Region.Watch(to);

    // COMPANY
    if (MetaDataRoutes.Company.required.includes(to.name)) return MetaDataRoutes.Company.Watch(to);

    // PERSON
    if (MetaDataRoutes.Person.required.includes(to.name)) return MetaDataRoutes.Person.Watch(to);

    // TEAMS
    if (MetaDataRoutes.Teams.required.includes(to.name)) return MetaDataRoutes.Teams.Watch(to);

    // FEE RELIEFS
    if (MetaDataRoutes.Fee_Relief.required.includes(to.name)) return MetaDataRoutes.Fee_Relief.Watch(to);

    // ANNUAL DUES
    if (MetaDataRoutes.Annual_Dues.required.includes(to.name)) return MetaDataRoutes.Annual_Dues.Watch(to);

    // MONTHLY REPORTING
    if (MetaDataRoutes.Monthly_Reporting.required.includes(to.name)) return MetaDataRoutes.Monthly_Reporting.Watch(to);

    // OFFICE GROUPS
    if (MetaDataRoutes.Office_Groups.required.includes(to.name)) return MetaDataRoutes.Office_Groups.Watch(to);

    // CONTACTS
    if (MetaDataRoutes.Contacts.required.includes(to.name)) return MetaDataRoutes.Contacts.Watch(to);

    // REPORTS
    if (MetaDataRoutes.Reports.required.includes(to.name)) return MetaDataRoutes.Reports.Watch(to);

    // Exchange Rates
    if (MetaDataRoutes.Exchange_Rates.required.includes(to.name)) return MetaDataRoutes.Exchange_Rates.Watch(to);

    // System Access
    if (MetaDataRoutes.System_Access.required.includes(to.name)) return MetaDataRoutes.System_Access.Watch(to);

    // System Access
    if (MetaDataRoutes.Roles.required.includes(to.name)) return MetaDataRoutes.Roles.Watch(to);
  }
}

const routeNames = [
  'Regions',
  'Create Region',
  'Read Region',
  'Edit Region',

  'Office Table',
  'EditOffice',
  'ViewOffice',
  'Create Office',

  'Company Table',
  'Create Company',
  'ReadCompany',
  'EditCompany',

  'Contracts',

  'Persons Table',
  'ViewPerson',
  'CreatePerson',
  'EditPerson',

  'Monthly Reporting',
  'MonthlyReportingRegionsReadMonths',
  'MonthlyReporting-Regions-Read-Months-Read-Offices',
  'MonthlyReporting-Regions-Read-Months-Read-Offices-Edit',

  'Annual Dues',
  'ViewFeeRelief',
  'Fee Relief Table',
  'Create Fee Relief',
  'EditFeeRelief',

  'AnnualDuesReportsOverview',
  'AnnualDuesReportsCurrent',
  'AnnualDuesReportsMonthlyOverview',
  'AnnualDuesReportsFinancialOverview',
  'AnnualDuesReportsEditFinancePayment',
  'AnnualDuesReportsAddFinancePayment',

  'Awards',

  'Teams Table',
  'CreateTeam',
  'EditTeam',
  'ViewTeam',

  'Office Groups Table',
  'Create Office Group',
  'View Office Groups',
  'Edit Office Groups',
  'View Ungrouped Offices',
  'Edit Ungrouped Offices',

  'Contacts List',
  'CreateContact',
  'EditContact',
  'ViewContact',

  'SyncLogList',
  'ViewSyncLog',

  'Exchange Rates',
  'Exchange Rates - Euro - Months',
  'Exchange Rates - Euro - Months - Read - Exchange Rates',
  'Days',
  'CreateExchangeRate',
  'EditExchangeRate',

  'International Dollar',
  'CreateInternationalDollarNew',
  'CreateInternationalDollarEdit',
  'CreateInternationalDollarView',
  'View International Dollar',
  'Edit International Dollar',

  'Settings',
  'Profile',

  'System Access',
  'User Permissions Table',
  'User Permissions Read',
  'EditUserPermission',
  'User Permissions Edit',
  'User Permissions Create',

  'System Roles Table',
  'System Roles Create',
  'System Roles Read',
  'System Roles Edit',

  'Reports',
  'Edit Report - Agents',
  'Create Report',

  'Administration',

  'Search',

  'NotificationsList',
  'NotificationsView',

  //dashboards
  'Summary',
  'Office Overview',
  'Office Dashboard',
  'Recruitment',
  'Data Glossary',

  // CM
  'CreateSaleContractIndex',
  'CreateSaleContract',
  'EditSaleContractDraftIndex',
  'EditSaleContractDraft',
  'EditSaleContractIndex',
  'EditSaleContract',
  'ReviewSaleContractIndex',
  'ReviewSaleContract',
  'CreateRenewalContractIndex',
  'CreateRenewalContract',
  'EditRenewalContractDraftIndex',
  'EditRenewalContractDraft',
  'EditRenewalContractIndex',
  'EditRenewalContract',
  'ReviewRenewalContract',
  'CreateTransferContract',
  'EditTransferContractDraft',
  'EditTransferContract',
  'ReviewTransferContract',
  'SavedForLater',
  'ContractsLog',
  'FeeApprovalQueue',
];
export default router;
