import router from '@/router';
import { PersonEditorService } from '../../../services/modules/personEditor.service';
import { BaseService } from '../../../services/base.service';

async function promiseAll(promises: Promise<any>[] = []) {
    return await Promise.all(promises.map(p => new Promise<any>(resolve => p.then(resolve).catch(e => resolve({ error: e })))));
}

function getPaginated(state, res) {
    const totalRows = Number(res?.data?.info?.numRows || '0');
    const rowsPerPage = state?.rowsPerPage || 35;
    const totalPages = Math.ceil(totalRows / rowsPerPage);
    const currentPage = (state?.currentPage || 1) > totalPages ? totalPages : (state?.currentPage || 1);
    return  {
        list: res?.data?.result || [],
        totalRows,
        rowsPerPage,
        currentPage,
        totalPages,
    };
}

export async function init(store, person_id) {
    const { commit, state } = store;

    commit('setState', { loadingPerson: true })

	if (person_id) await getPerson(store, person_id);

    const promises = [
        PersonEditorService.sessionPermissions(),
        PersonEditorService.personSuffix(),
        PersonEditorService.specializations({ 'textFilter[showInPerson]': '1', }),
        PersonEditorService.billingInformation(),
        PersonEditorService.designations(),
        PersonEditorService.languages(),
        PersonEditorService.emailCommunicationTags(),
        PersonEditorService.countries(),
        PersonEditorService.countries({ 'filter[remaxEuropeCountry][eq]': '1', }),
        PersonEditorService.phonecodes(),
        PersonEditorService.contactInformationEntryCategories({ 'textFilter[isPhoneType]': '1', }),
        PersonEditorService.contactInformationEntryCategories({ 'textFilter[isEmailType]': '1', }),
        PersonEditorService.contactInformationEntryTypes(),
        PersonEditorService.personalNotesCategories(),
        PersonEditorService.getTerminationReasons(),
    ];

    const [
        session,
        personSuffix,
        specializations,
        billingInformation,
        designations,
        languages,
        emailCommunicationTags,
        allCountries,
        europeanCountries,
        phonecodes,
        phoneCategories,
        emailCategories,
        contactInformationEntryTypes,
        personalNotesCategories,
        terminationReasons,        
    ] = await promiseAll(promises);

    commit('setState', {
        loadingPerson: false,
        personSuffix: personSuffix?.data?.result || [],
        specializations: specializations?.data?.result || [],
        billingInformation: billingInformation?.data?.result || [],
        designationsDropdown: designations?.data?.result || [],
        languages: languages?.data?.result || [],
        emailCommunicationTags: emailCommunicationTags?.data?.result || [],
        allCountries: allCountries?.data?.result || [],
        europeanCountries: europeanCountries?.data?.result || [],
        phonecodes: phonecodes?.data?.result || [],
        phoneCategories: phoneCategories?.data?.result || [],
        emailCategories: emailCategories?.data?.result || [],
        contactInformationEntryTypes: contactInformationEntryTypes?.data?.result || [],
        personalNotesCategories: personalNotesCategories?.data?.result || [],
        terminationReasons: terminationReasons?.data?.result || [],
        session: { ...state.session, ...session?.data },
    });
}

export async function getPerson(store, person_id) {
    const { commit, state, } = store;

	let personRes = null;
    try {
        personRes = await PersonEditorService.person(person_id);
    } catch (e) {
        return router.push({ name: 'Persons Table' });
    }

	const person = personRes?.data?.result || personRes?.data;

    const [region, regionCurrencyReporting] = await promiseAll([
        PersonEditorService.region(person?.regionID),
        PersonEditorService.regionCurrencyReporting(person?.regionID),
    ]);

    let teamLeader = null;
    if (person?.teamID) teamLeader = await PersonEditorService.teamLeader(person?.teamID);

    const res = await promiseAll([
        PersonEditorService.designation(person_id),
        PersonEditorService.language(person_id),
        PersonEditorService.emailCommunicationTag(person_id),
        PersonEditorService.primaryTitleHistories(person_id),
        PersonEditorService.contactInformationEntry(person_id),
        PersonEditorService.licenseNumbers(person_id),
        PersonEditorService.ranks(person_id),
        PersonEditorService.statistics(person_id),
        PersonEditorService.offices(person_id),
        PersonEditorService.canEditPersonTerminationReason(person_id),
        PersonEditorService.awards(person_id),
        PersonEditorService.amountOfYearsGettingClubAwards(person_id),
        PersonEditorService.active(person_id),
        PersonEditorService.relationWithInactive(person_id),        
        PersonEditorService.isInActiveOfficeShare(person_id),
        PersonEditorService.teamHistories(person_id),
        PersonEditorService.systemAccesses(person_id),
        PersonEditorService.team(person_id),
        PersonEditorService.remaxTitles(person_id),
		PersonEditorService.remaxTitles(person_id, {
			only_active: undefined,
			active_and_future: 1,
			sort: '-valid_from',
		}),
        PersonEditorService.note(person_id, {
            limit: `${((state.notes?.currentPage || 1) - 1) * (state.notes?.rowsPerPage || 35)},${(state.notes?.rowsPerPage || 35)}`,
        }),
        PersonEditorService.editHistory(person_id, {
            limit: `${((state.editHistory?.currentPage || 1) - 1) * (state.editHistory?.rowsPerPage || 35)},${(state.editHistory?.rowsPerPage || 35)}`,
        }),
		BaseService.get(`personHasOffice/${person_id}`),
    ]);

    const statesRes = person?.countryISO ? await PersonEditorService.countryStates(person?.countryISO) : null;

    const [
        designation,
        language,
        emailCommunicationTag,
        primaryTitleHistories,
        contactInformationEntry,
        licenseNumbers,
        ranks,
        statistics,
        offices,
        canEditPersonTerminationReason,
        awards,
        amountOfYearsGettingClubAwards,
        active,
        relationWithInactive,
        isInActiveOfficeShare,
        teamHistories,
        systemAccesses,
        team,
        remaxTitles,
		remaxTitlesActiveAndFuture,
        note,
        editHistory,
    ] = res;

    commit('setState', {
        person: person || {},
        designations: designation?.data?.result || [],
        language: language?.data?.result || [],
        emailCommunicationTag: emailCommunicationTag?.data?.result || [],
        primaryTitleHistories: primaryTitleHistories?.data?.result || [],
        contactInformationEntry: contactInformationEntry?.data?.result || [],
        licenseNumbers: licenseNumbers?.data?.result || [],
        currencyReporting: regionCurrencyReporting?.data || {},
        ranks: ranks?.data?.result || {},
        statistics: statistics?.data?.result || {},
        offices: offices?.data?.result || [],
        region: region?.data?.result || {},
        canEditPersonTerminationReason: canEditPersonTerminationReason?.data?.result?.canEditPersonTerminationReason || false,
        awards: awards?.data?.result || [],
        amountOfYearsGettingClubAwards: amountOfYearsGettingClubAwards?.data?.amount || null,
        active: active?.data?.result || [],
        teamLeader: teamLeader?.data?.result || [],
        relationWithInactive: relationWithInactive?.data?.result || [],
        isInActiveOfficeShare: isInActiveOfficeShare?.data?.result?.isInActiveOfficeShare || false,
        states: statesRes?.data?.result || [],
        teamHistories: teamHistories?.data?.result || [],
        systemAccesses: systemAccesses?.data?.result || [],
        team: team?.data?.result || [],
		remaxTitles: {
			...state.remaxTitles,
			list: remaxTitles?.data?.result || [],
			activeAndFuture: remaxTitlesActiveAndFuture?.data?.result || [],
		},
		primaryTitle: (remaxTitles?.data?.result || []).filter(t => `${t.isPrimaryTitle}` === '1')[0] || null,
        ...(!note?.data?.result ? {} : { notes: getPaginated(store.state.notes, note) }),
        ...(!editHistory?.data?.result ? {} : { editHistory: getPaginated(store.state.editHistory, editHistory) }),
    });

    return [
        personRes,
        ...res
    ];
}
