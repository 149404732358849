import { AffiliateDashboardService } from '@/services/modules/affiliateDashboard.service';
import { BaseService } from '@/services/base.service';

export default {
    namespaced: true,
    state: {
        pieChart: [],
        productivityChart: [],
        averageTransactionsByAgentData: [],
        agentAverageTransactionsPerTenureGroupData: [],
        commissionInsightData: [],
        agentOverviewData: [],
        officeTreeStructureData: [],
    },
    getters: {
        getAgentCountData: (state) => state.pieChart,
    },
    mutations: {
        setAgentCountData(state, data) {
            state.pieChart = data;
        },
        setAgentProductivityData(state, data) {
            state.productivityChart = data;
        },
        setAverageTransactionsPerYearData(state, data) {
            state.averageTransactionsByAgentData = data;
        },
        setAgentAverageTransactionsPerTenureGroupData(state, data) {
            state.agentAverageTransactionsPerTenureGroupData = data;
        },
        setCommissionInsightData(state, data) {
            state.commissionInsightData = data;
        },
        setAgentOverviewData(state, data) {
            state.agentOverviewData = data;
        },
        setOfficeTreeStructureData(state, data) {
            state.officeTreeStructureData = data;
        },
    },
    actions: {
        async getAgentCountData({ commit, state }, params) {
            const res = await AffiliateDashboardService.getAgentCountData(params);
            commit('setAgentCountData', res.data?.data?.result ?? []);
            return res;
        },
        async getAgentProductivityData({ commit, state }, params) {
            const res = await AffiliateDashboardService.getAgentProductivityData(params);
            commit('setAgentProductivityData', res.data?.data?.result ?? []);
            return res;
        },
        async getAverageTransactionsPerYearData({ commit, state }, params) {
            const res = await AffiliateDashboardService.getAverageTransactionsPerYearData(params);
            commit('setAverageTransactionsPerYearData', res.data?.data?.result ?? []);
            return res;
        },
        async getAgentAverageTransactionsPerTenureGroupData({ commit, state }, params) {
            const res = await AffiliateDashboardService.getAgentAverageTransactionsPerTenureGroupData(params);
            commit('setAgentAverageTransactionsPerTenureGroupData', res.data?.data?.result ?? []);
            return res;
        },
        async getAgentCommissionInsightData({ commit, state }, params) {
            const res = await AffiliateDashboardService.getAgentCommissionInsightData(params);
            commit('setCommissionInsightData', res.data.data.result);
            return res;
        },
        async getAgentOverviewData({ commit, state }, params) {
            const res = await AffiliateDashboardService.getAgentOverviewData(params);
            if (res.data.data.result == '') {
                res.data.data.result = [];
            }
            commit('setAgentOverviewData', res.data.data.result);
            return res;
        },
        async getOfficeTreeStructure({ commit, state }, params) {
            const res = await AffiliateDashboardService.getOfficeTreeStructure(params);
            commit('setOfficeTreeStructureData', res.data?.data?.result ?? []);
            return res;
        },
    },
};
