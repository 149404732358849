export default {
  cache: require('./cache').default,
  auth: require('./auth').default,
  dashboardOfficeDashboard: require('./dashboardOfficeDashboard').default,
  currency: require('./currency').default,
  awards: require('./awards').default,
  feeReliefs: require('./feeReliefs').default,
  feeReliefEditor: require('./feeReliefEditor').default,
  teams: require('./teams').default,
  teamEditor: require('./teamEditor').default,
  annualDuesReportsOverview: require('./annualDuesReportsOverview').default,
  annualDuesReportsMonthlyOverview: require('./annualDuesReportsMonthlyOverview').default,
  annualDuesReportsCurrent: require('./annualDuesReportsCurrent').default,
  annualDuesReportsFinanceOverview: require('./annualDuesReportsFinanceOverview').default,
  annualDuesReportsFinancePayment: require('./annualDuesReportsFinancePayment').default,
  contactMgmtContacts: require('./contactMgmtContacts').default,
  contactEditor: require('./contactEditor').default,
  contractEditor: require('./contractEditor').default,
  contractLog: require('./contractLog').default,
  contractsSavedForLater: require('./contractsSavedForLater').default,
  contractsFeeApprovals: require('./contractsFeeApprovals').default,
  office: require('./office').default,
  toast: require('./toast').default,
  dom: require('./dom').default,
  country: require('./country').default,
  region: require('./region').default,
  regionEditor: require('./regionEditor').default,
  userPermissions: require('./userPermissions').default,
  packageManagement: require('./packageManagement').default,
  systemPermissions: require('./systemPermissions').default,
  systemDebugger: require('./systemDebugger').default,
  person: require('./person').default,
  common: require('./common').default,
  company: require('./company').default,
  search: require('./search').default,
  changeHistory: require('./changeHistory').default,
  notes: require('./notes').default,
  monthlyReporting: require('./monthlyReporting').default,
  personEditor: require('./personEditor').default,
  officeEditor: require('./officeEditor').default,
  franchiseDetails: require('./franchiseDetails').default,
  franchiseSalesDashboard: require('./franchiseSalesDashboard').default,
  annualDues: require('./annualDues/reports/report').default,
  officeGroups: require('./officeGroups').default,
  officeGroupEditor: require('./officeGroupEditor').default,
  syncLog: require('./syncLog').default,
  contactMgmt: require('./contactMgmt').default,
  annualDuesPaymentEditor: require('./annualDuesPaymentEditor').default,
  exchangeRates: require('./exchangeRates').default,
  exchangeRatesEditor: require('./exchangeRatesEditor').default,
  globalSearch: require('./globalSearch').default,
  systemAccess: require('./systemAccess').default,
  notifications: require('./notifications').default,
  cube: require('./cube').default,
  reports: require('./reports').default,
  reportsList: require('./reportsList').default,
  reportEditor: require('./reportEditor').default,
  affiliateDashboard: require('./affiliateDashboard').default,
};
