import { BaseService } from '../base.service';

export class AffiliateDashboardService extends BaseService {
    static get entity() {
        return 'affiliateDashboard';
    }

    static async getAgentCountData(params) {
        try {
            let api = `v1/affiliate/getAgentCount?regionId=${params.regionID}`;

            if (params.officeID) {
                api += `&officeId=${params.officeID}`;
            }

            const response = await this.get(api);
            return response;
        } catch (error) {
            throw error;
        }
    }

    static async getAgentProductivityData(params) {
        try {
            let api = `v1/affiliate/getAgentProductivity?regionId=${params.regionID}`;

            if (params.officeID) {
                api += `&officeId=${params.officeID}`;
            }

            const response = await this.get(api);
            return response;
        } catch (error) {
            throw error;
        }
    }

    static async getAverageTransactionsPerYearData(params) {
        try {
            let api = `v1/affiliate/getAgentAverageTransactionsPerYear?regionId=${params.regionID}`;

            if (params.officeID) {
                api += `&officeId=${params.officeID}`;
            }

            const response = await this.get(api);
            return response;
        } catch (error) {
            throw error;
        }
    }

    static async getAgentAverageTransactionsPerTenureGroupData(params) {
        try {
            let api = `v1/affiliate/getAgentAverageTransactionsPerTenureGroup?regionId=${params.regionID}`;

            if (params.officeID) {
                api += `&officeId=${params.officeID}`;
            }

            const response = await this.get(api);
            return response;
        } catch (error) {
            throw error;
        }
    }

    static async getAgentCommissionInsightData(params) {
        try {
            // const response = await this.get(
            //   `/v1/affiliate/getAgentCommissionInsights?gender=${params.gender}&officeId=${params.officeID}&regionId=${params.regionID}&ageCategory=${params.ageCategory}`
            // );
            const response = await this.post(`/v1/affiliate/getAgentCommissionInsights`, params);
            return response;
        } catch (error) {
            throw error;
        }
    }

    static async getAgentOverviewData(params) {
        try {
            let api = `/v1/affiliate/getAgentOverview?regionId=${params.regionID}`;

            if (params.searchCategory) {
                api += `&searchCategory=${params.searchCategory}`;
            }

            if (params.officeID) {
                api += `&officeId=${params.officeID}`;
            }

            const response = await this.get(api);
            return response;
        } catch (error) {
            throw error;
        }
    }

    static async getOfficeTreeStructure(params) {
        try {
            let api = `/v1/affiliate/getOfficeTreeStructure?regionId=${params.regionID}`;

            if (params.officeID) {
                api += `&officeId=${params.officeID}`;
            }

            const response = await this.get(api);
            return response;
        } catch (error) {
            throw error;
        }
    }
}
