export default [
    {
        label: 'Start up Sales',
        short_label: 'Start up',
        value: 'startUpSales',
        description: 'New sale to a prospect (incl. any RE/MAX agents) who is not operating a real estate office.',
    },
    {
        label: 'Expansion Sale',
        short_label: 'Expansion',
        value: 'expansionSale',
        description: 'Existing RE/MAX Franchisee opening another office.',
    },
    {
        label: 'Satellite',
        short_label: 'Satellite',
        value: 'satellite',
        description:
        'The office may not function on its own and shall be connected and report through a main office of the same Franchisee.\n*Satellite structure is subject to RE/MAX Europe approval',
    },
    {
        label: 'Conversion',
        short_label: 'Conversion',
        value: 'conversion',
        // description: 'Existing real estate brokerage converted to RE/MAX',
        description: 'Operating real estate office converted to RE/MAX with at least 1 person and existing commissions.',
    },
];
